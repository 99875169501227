import axios from "axios";
import Constants from "../utils/constants";


const _getAddressByZipCode = async (zipCode) => {
  const unmasked = zipCode.replace(/[^\d]+/g, "");
  const url = `${Constants.viaCepBaseUrl}/${unmasked}/json/`;
  return await axios.get(url);
};

const setBodyBackGroundColor = (color) => {
  document.body.style.backgroundColor = color;
};


export {
    _getAddressByZipCode,
    setBodyBackGroundColor
};

