import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PlanComponent from "../components/PlanComponent/PlanComponent";
import { setBodyBackGroundColor } from "../services/commonService";
import { notifyError } from "../services/noticationService";
import Constants from "../utils/constants";
import Endpoints from "../utils/endpoints";

function PlanList(props) {
  const [planList, setPlanList] = useState([]);
  const PLAN_TYPE = "Business";

  setBodyBackGroundColor("rgba(231,231, 231,1)");

  useEffect(() => {
    const payload = {
      id: "sds"
    }

    axios.post(Constants.customerApiBaseUrl + Endpoints.getSubscriptions, payload).then((response)=> {
      debugger
      if(!response.data.result.successCode)
        throw new Error(response.data.result.msg);
     
      const filteredPlans = response.data.result.subscriptionData.filter(a => a.customerType === PLAN_TYPE);
      setPlanList(filteredPlans);

      
    }).catch((error) => {
      Bugsnag.notify(new Error(error));
      notifyError("Ocorreu um erro ao buscar os dados");
    });
  }, [])

  return (
    <Root>
      <Body>
        <TabsStack>
          <Container>
            <div className="text-center mb-4">
              <Planos>Lista de planos</Planos>
            </div>
            <ScrollArea className="col-md-10 mb-3 row ml-auto mr-auto justify-content-center">
              <div className="row">
                {planList.map((data, index) => 
                  <div key={index} className="col-sm-11 ml-auto mr-auto col-md-6 mb-5">
                    <PlanComponent  data={data}
                      style={{
                        marginTop: 25,
                        marginLeft: 7,
                        flex: "0 0 auto"
                      }}
                    ></PlanComponent>
                  </div>
                )}
              </div>
            </ScrollArea>
          </Container>
        </TabsStack>
      </Body>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Body = styled.div`
  padding-top: 10vh;
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Planos = styled.span`
  font-family: Arial;
  font-style: normal;
  font-weight: 600;
  color: #121212;
  font-size: 30px;
  margin-top: 23px;
`;

const ScrollArea = styled.div`
  /* overflow-y: scroll; */
`;

const TabsStack = styled.div`
  flex: 1 1 0%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default PlanList;
