import React from "react";
import styled from "styled-components";
import { setBodyBackGroundColor } from "../services/commonService";
import { setCustomerAsSignOut } from "../services/customerService";




function CartCongrats() {

  setBodyBackGroundColor("rgb(255,255,255)");
  setCustomerAsSignOut();

  return (
    <Root>
      <Body>
        <Headline>
          <Image>
            <Overlay>
              <TudoCerto>Tudo Certo!</TudoCerto>
              <Image2 src={require("../assets/images/Asset_1241.png")}></Image2>
              <Followers>
                Tudo certo, você vai receber um {"\n"}email confirmando a criação da assinatura
              </Followers>
            </Overlay>
          </Image>
        </Headline>
      </Body>
    </Root>
  )

}
const Root = styled.div`
display: flex;
background-color: rgb(255,255,255);
flex-direction: column;
height: 100vh;
width: 100vw;
`;

const Body = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Headline = styled.div`
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  display: flex;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  height: 100%;
  background-size: cover;
`;

const Overlay = styled.div`
  background-color: rgba(238,243,255,1);
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const TudoCerto = styled.span`
  font-family: Arial;
  color: rgba(74,74,74,1);
  font-size: 24px;
  font-weight: bold;
  margin-top: 43px;
  align-self: center;
`;

const Image2 = styled.img`
  width: 200px;
  height: 100%;
  margin-top: 63px;
  margin-left: 40vw;
  object-fit: contain;
`;

const Followers = styled.span`
  font-family: Arial;
  color: rgba(74,74,74,1);
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  margin-top: 23px;
  align-self: center;
  width: 80%;
  margin-bottom: 3%;
`;

export default CartCongrats;