import MaterialUISwitch from "@material-ui/core/Switch";
import React from "react";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import MaterialCommunityIconsIcon from "react-native-vector-icons/dist/MaterialCommunityIcons";
import styled from "styled-components";
import HeaderX from "../components/HeaderX";
import { setBodyBackGroundColor } from "../services/commonService";

function Settings() {
  setBodyBackGroundColor("rgb(255,255,255)");
  const userData = JSON.parse(localStorage.getItem("userData"));


  return (
    <Root>
      <HeaderX
        style={{
          height: 80,
          boxShadow: "1px 7px 5px  0.1px rgba(0,0,0,1) ",
        }}
        icon2Name="power"
      ></HeaderX>
      <Body>
        <Circulo>
          <PageName>Configurações</PageName>
          <UserInfo>
            <AvatarStackRow>
              <AvatarStack>
                <svg
                  viewBox="0 0 100.45 100.3"
                  style={{
                    top: 0,
                    left: 0,
                    width: 100,
                    height: 100,
                    position: "absolute",
                  }}
                >
                  <ellipse
                    stroke="rgba(230, 230, 230,1)"
                    strokeWidth={0}
                    fill="rgba(230, 230, 230,1)"
                    cx={50}
                    cy={50}
                    rx={50}
                    ry={50}
                  ></ellipse>
                </svg>
                <MaterialCommunityIconsIcon
                  name="account"
                  style={{
                    top: 11,
                    left: 15,
                    position: "absolute",
                    color: "rgba(128,128,128,1)",
                    fontSize: 70,
                  }}
                ></MaterialCommunityIconsIcon>
              </AvatarStack>
            </AvatarStackRow>
          </UserInfo>
          <SettingsList>
            <AccountSettings>
              <UserName>{userData.userName}</UserName>
              <UserEmail className="mb-4">{userData.userEmail}</UserEmail>
              <Expanded>Configurações da Conta</Expanded>
              <SubSettings>
                <Text10>Dados pessoais</Text10>
                <IoniconsIcon
                  name="ios-arrow-forward"
                  style={{
                    color: "rgba(74,74,74,1)",
                    fontSize: 30,
                  }}
                ></IoniconsIcon>
              </SubSettings>
            </AccountSettings>

            <Notifications>
              <Text7>Notificações</Text7>
              <Switch3>
                <MaterialUISwitch
                  color="primary"
                  checked={true}
                  style={{ color: "rgba(74,74,74,1)" }}
                ></MaterialUISwitch>
              </Switch3>
            </Notifications>
          </SettingsList>
        </Circulo>
      </Body>
    </Root>
  );
}
const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const Body = styled.div`
  width: auto;
  max-width: 360px;
  @media (max-width: 450px) {
    max-width: initial;
  }
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
  background-image: linear-gradient(
    180deg,
    rgba(74, 74, 74, 1) 0%,
    #eeeeee 100%
  );
`;
const Circulo = styled.div`
  background: #fff;
  height: 90%;
  margin: 10% 0%;
  border-radius: 100% 0% 0% 100% / 51% 54% 46% 49%;
  margin-left: -40px;
`;
const SettingsList = styled.div`
  left: 51px;
  bottom: 272px;
  flex-direction: column;
  display: flex;
  margin-top: 40%;
  margin-left: 11%;
  @media (max-width: 350px) {
    margin-top: 73%;
    margin-left: 14%;
  }
  @media only screen and (max-width: 1350px) and (min-width: 601px) {
    margin-top: 56%;
  }
`;

const AccountSettings = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 15px;
  margin-left: 24px;
  margin-right: 24px;
`;

const Expanded = styled.span`
  font-family: Arial;
  color: #121212;
  font-size: 18px;
  font-weight: 700;
  margin-top: -3px;
`;

const SubSettings = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
`;
const Text10 = styled.span`
  font-family: Arial;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  margin-top: 6px;
`;
const Notifications = styled.div`
  height: 27px;
  display: flex;
  justify-content: space-between;
  margin: 0px 29px;
  margin-top: 10px;
`;

const Text7 = styled.span`
  font-family: Arial;
  color: #121212;
  font-size: 18px;
  font-weight: 700;
`;
const Switch3 = styled.div`
  width: 40px;
`;
const PageName = styled.span`
  font-family: Arial;
  left: 33px;
  margin-top: -17px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  font-size: 21px;
  font-weight: bold;
`;
const UserInfo = styled.div`
  left: 33px;
  height: 125px;
  position: absolute;
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 600px) and (min-width: 200px) {
    margin-top: 11%;
  }
  @media only screen and (max-width: 1350px) and (min-width: 601px) {
    margin-top: 5%;
  }
  @media only screen and (max-width: 2300px) and (min-width: 1351px) {
    margin-top: 2.5%;
  }
`;
const AvatarStack = styled.div`
  width: 100px;
  height: 100px;
  margin-top: 6px;
  position: relative;
`;
const UserName = styled.span`
  font-family: Arial;
  color: rgba(74, 74, 74, 1);
  font-size: 30px;
  margin-top: 1px;
  @media (max-width: 350px) {
    font-size: 22px;
  }
`;
const UserEmail = styled.span`
  font-family: Arial;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  margin-top: 5px;
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const AvatarStackRow = styled.div`
  height: 110px;
  flex-direction: row;
  display: flex;
  margin-top: -4px;
  margin-left: 1px;
  margin-right: 7px;
`;
export default Settings;
