import React from "react";
import styled from "styled-components";
import { setBodyBackGroundColor } from "../services/commonService";

function SingUpConfirmation1() {
  setBodyBackGroundColor("rgba(74,74,74,1)");
  
  return (
    <Root>
      <Body>
        <Headline>
          <Image>
            <Overlay>
              <TudoCerto>Tudo Certo!</TudoCerto>
              <Image2 src={require("../assets/images/Asset_216.png")}></Image2>
              <Followers className="p-3">
                Nossa Equipe vai validar {"\n"}seu cadastro e liberar o acesso
                {"\n"}se tudo estiver Ok!{"\n"}
                {"\n"}Você receberá um email{"\n"}avisando quando tudo {"\n"}
                estiver pronto.{"\n"}
                {"\n"}Até lá!
              </Followers>
            </Overlay>
          </Image>
        </Headline>
      </Body>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Body = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Headline = styled.div`
  overflow: hidden;
  flex-direction: column;
  display: flex;
`;

const Image = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Overlay = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const TudoCerto = styled.span`
  font-family: Arial;
  color: rgba(255,255,255,1);
  font-size: 24px;
  font-weight: bold;
  margin-top: 43px;
  align-self: center;
`;

const Image2 = styled.img`
  width: 200px;
  height: 100%;
  margin-top: 38px;
  margin-left: 80px;
  object-fit: contain;
`;

const Followers = styled.span`
  font-family: Arial;
  color: rgba(255,255,255,1);
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  margin-top: 48px;
  align-self: center;
`;

export default SingUpConfirmation1;
