import React from "react";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";

import "./style.css";
import { getValueCurrencyFormatted } from "../../utils/commonFunctions";

function PlanComponent(props) {
  const history = useHistory();
  debugger
  const onSelectPlan = () => {
    localStorage.setItem("subsCriptionId", props.data._id);
    history.push("/PlanDetails");
  };

  return (
    <Card style={{ height: "380px" }}>
      <Card.Body>
        <Card.Title className="font-weight-bold" style={{ fontSize: "25px" }}>
          {props.data.productName}
        </Card.Title>
        <Card.Text>{props.data.marketingText}</Card.Text>
        <div>
          <label className="lbl-Fee label">
            a partir de
            <span className="pl-2">
              {getValueCurrencyFormatted(
                props.data.feesCharge.baselineRate -
                  (props.data.feesCharge.baselineRate *
                    props.data.plans[3].discount) /
                    100
              )}
            </span>
          </label>
        </div>
      </Card.Body>
      <Card.Footer>
        <button
          className="btn mb-3 col-md-11 col-11 ml-3 btn-select-plan cp"
          onClick={onSelectPlan}
        >
          Selecionar
        </button>
      </Card.Footer>
    </Card>
  );
}

export default PlanComponent;
