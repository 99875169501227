import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import EvilIconsIcon from "react-native-vector-icons/dist/EvilIcons";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { isCpfValid } from "../../common/validator/common.validations";
import { setBodyBackGroundColor } from "../../services/commonService";
import {
  createSubscription,
  generateGatewayHash,
  updateCard,
} from "../../services/customerService";
import { _updateCollectionDataById } from "../../services/firebaseSevice";
import { notifyError, notifyWarn } from "../../services/noticationService";
import "./styles.css";

function CartPaymentInformation() {
  const [cardHolderName, setCardHolderName] = useState({value: ''});
  const [cardNumber, setCardNumber] = useState({value: ''});
  const [cardCVV, setCardCVV ] = useState({value: ''});
  const [cardExpirationDate, setCardExpirationDate] = useState({value: ''});
  const [cardHolderCpf, setCardHolderCpf] = useState({value: ''});
  const history = useHistory();
  setBodyBackGroundColor("rgba(238, 243, 255, 1)");

  const { register, errors, trigger } = useForm({
    mode: "onBlur",
  });

  const onSubmitCard = async () => {
    trigger();
    // console.log(Object.keys(errors));
    if (Object.keys(errors).length === 0) {
      try {
        const customerPlanData = JSON.parse(
          localStorage.getItem("customerPlanData")
        );

        const hash = await generateGatewayHash();

        const response = encrypt(hash.data.public_key);

        const card_hash = hash.data.id + "_" + response;

        const params = {
          userId: customerPlanData.customer_id,
          card_hash: card_hash,
          cpf: cardHolderCpf.value,
          phoneNumber: customerPlanData.phone,
          billingAddress: customerPlanData.billingAddress,
        };
        ;
        //TODO: [ZEN-1010] Fazer o tratamento de erro para falha na criação do cartão
        await updateCard(customerPlanData.customer_token, params);
        //BUG: [ZEN-1011] Fazer tratamento de erro para falha na criação da assinatura em modo cartão
        await createSubscription();

        const collectionName = "customerPlanData";

        const payloadFinished = {
          isFinished: true,
        };

        await _updateCollectionDataById(
          customerPlanData._id,
          collectionName,
          payloadFinished
        );
        history.push('/CartCongrats/')

      } catch (error) {
        notifyError("Ocorreu um erro ao salvar os dados");
      }
    } else {
      notifyWarn("Preencha corretamente todos os campos");
    }
  };

  const encrypt = (publicKey) => {
    const $encrypt = new JSEncrypt();
    const encodedHash = createEncodeString();
    // debugger
    $encrypt.setPublicKey(publicKey);
    return $encrypt.encrypt(encodedHash);
  };

  const createEncodeString = () => {
    return encodeURI(
      "card_number=" +
        cardNumber.value +
        "&card_holder_name=" +
        cardHolderName.value +
        "&card_expiration_date=" +
        cardExpirationDate.value.replace("/", "") +
        "&card_cvv=" +
        cardCVV.value
    );
  };

  const validateCpfNumber = (value) => {
    // debugger
    if (!value) return "CPF é obrigatório";
    // debugger

    if (!isCpfValid(value)) return "Favor digitar um CPF válido";
    // debugger

    return true;

  };

  return (
    <Root>
      <PlanosColumn>
        <Planos>Dados do Cartão de Crédito:</Planos>
        <Group5>
          <div className="mb-3">
            <Rect1>
              <EvilIconsIcon
                name="user"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 30,
                  marginLeft: 20,
                  marginTop: 17,
                }}
              ></EvilIconsIcon>
              <UsernameInput1
                ref={register({ required: true })}
                name={"cardHolderName"}
                value={cardHolderName.value}
                onChange={(event) =>
                  setCardHolderName({ value: event.target.value })
                }
                placeholder="Roberto Damasceno"
                // secureTextEntry={false}
              ></UsernameInput1>
            </Rect1>
            {errors.cardHolderName && (
              <div>
                <span>Nome é obrigatório</span>
              </div>
            )}
          </div>
          <div className="mb-3">
            <Group>
              <EvilIconsIcon
                name="credit-card"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 30,
                  marginLeft: 20,
                  marginTop: 17,
                }}
              ></EvilIconsIcon>
              <TextInput
                ref={register({ required: true })}
                value={cardNumber.value}
                name="cardNumber"
                onChange={(event) =>
                  setCardNumber({ value: event.target.value })
                }
                placeholder="5152603879891105"
                // secureTextEntry={false}
              ></TextInput>
            </Group>
            {errors.cardNumber && (
              <div>
                <span>Número do cartão é obrigatório</span>
              </div>
            )}
          </div>
          <div className="mb-3">
            <Group2>
              <EvilIconsIcon
                name="lock"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 30,
                  marginLeft: 20,
                  marginTop: 17,
                }}
              ></EvilIconsIcon>
              <TextInput2
                ref={register({ required: true })}
                name="cardCVV"
                value={cardCVV.value}
                onChange={(event) =>
                  setCardCVV({ value: event.target.value })
                }
                placeholder="CVV"
                // secureTextEntry={false}
                maxLength={3}
              ></TextInput2>
            </Group2>
            {errors.cardCVV && (
              <div>
                <span>CVV é obrigatório</span>
              </div>
            )}
          </div>
          <div className="mb-3">
            <Group3>
              <EvilIconsIcon
                name="clock"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 30,
                  marginLeft: 20,
                  marginTop: 17,
                }}
              ></EvilIconsIcon>
              <InputMask
                inputRef={register({ required: true })}
                className="input-masked"
                name="cardExpirationDate"
                value={cardExpirationDate.value}
                onChange={(event) =>
                  setCardExpirationDate({ value: event.target.value })
                }
                placeholder="02/22"
                // secureTextEntry={false}
                mask="99/99"
              ></InputMask>
            </Group3>
            {errors.cardExpirationDate && (
              <div>
                <span>Data de validade é obrigatória</span>
              </div>
            )}
          </div>
          <div className="mb-3">
            <Group4>
              <FontAwesomeIcon
                name="id-card-o"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 20,
                  width: 34,
                  height: 30,
                  marginLeft: 20,
                  marginTop: 17,
                }}
              ></FontAwesomeIcon>
              <InputMask
                inputRef={register({
                  validate: {
                    isValidCpfteste: validateCpfNumber,
                    message: "Favor digitar um cpf válido",
                  },
                })}
                className="input-masked"
                name="cardHolderCpf"
                value={cardHolderCpf.value}
                onChange={(event) =>
                  setCardHolderCpf({ value: event.target.value })
                }
                placeholder="CPF"
                // secureTextEntry={false}
                mask="999.999.999-99"
              ></InputMask>
            </Group4>
            {errors.cardHolderCpf && (
              <div>
                <span>{errors.cardHolderCpf.message}</span>
              </div>
            )}
          </div>
        </Group5>
        <ImageRow>
          <Image src={require("../../assets/images/image_CqvV..png")}></Image>
          <LoremIpsum>
            Ambiente Seguro de pagamento de Cartão{"\n"}Este é um ambiente
            seguro 2048-bit SSL encrypted payment.
          </LoremIpsum>
        </ImageRow>
        <Button1 className="mt-2 ml-1 mb-3" onClick={() => onSubmitCard()}>
          <button
            style={{
              backgroundColor: "#1193ff",
              color: "white",
              height: "50px",
            }}
            className="btn mb-3 col-md-11 col-11 ml-3"
          >
            Aceitar
          </button>
        </Button1>
      </PlanosColumn>
    </Root>
    // <Root>
    //   <PlanosColumn>
    //     <ImageRow>
    //       <Image src={require("../assets/images/image_CqvV..png")}></Image>
    //       <LoremIpsum >
    //         Ambiente Seguro de pagamento de Cartão{"\n"}Este é um ambiente
    //         seguro 2048-bit SSL encrypted payment.
    //       </LoremIpsum>
    //     </ImageRow>
    //     <Link to="/CustomerInfo" className="mt-2">
    //       <Button1>
    //         <ButtonOverlay>
    //           <button
    //             style={{ backgroundColor: "#1193ff", color: "white", height: "50px" }}
    //             className="btn mb-3 col-md-11 col-11 ml-3"
    //           >
    //             Aceitar
    //         </button>
    //         </ButtonOverlay>
    //       </Button1>
    //     </Link>
    //   </PlanosColumn>
    // </Root>
  );
}
const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Planos = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  font-size: 20px;
  margin-left: 8px;
`;

const Group5 = styled.div`
  @media only screen and (max-width: 350px) {
    width: 88%;
    margin: auto;
  }
  width: 305px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 27px;
  margin-left: 27px;
  display: flex;
`;

const Rect1 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const UsernameInput1 = styled.input`
  width: 100%;
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const TextInput = styled.input`
  width: 100%;
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group2 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const TextInput2 = styled.input`
  width: 100%;
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group3 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const Group4 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const Image = styled.img`
  height: 31px;
  width: auto;
  margin-right: 5px;
`;

const LoremIpsum = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  font-size: 11px;
  margin-top: 5px;
`;

const ImageRow = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 27px;
  margin-left: 27px;
  width: 305px;
  @media only screen and (max-width: 400px) {
    width: 85%;
  }
`;

const PlanosColumn = styled.div`
  flex-direction: column;
  margin-top: 18px;
  margin-right: 12px;
  display: flex;
`;

const Button1 = styled.div`
  width: 345px;
  height: 54px;
  flex-direction: column;
  display: flex;
  border: none;
  @media only screen and (max-width: 350px) {
    width: 100%;
  }
`;

export default CartPaymentInformation;

/* return (
    <Root>
      <PlanosColumn>
        <Planos className="ml-4">Dados do Cartão de Crédito:</Planos>
        <form onSubmit={handleSubmit(onSubmitCard)}>
          <Group5>
            <div className="mb-3">
              <Rect1>
                <EvilIconsIcon
                  name="user"
                  style={{
                    color: "rgba(155,155,155,1)",
                    fontSize: 30,
                    marginLeft: 20,
                    marginTop: 17,
                  }}
                ></EvilIconsIcon>
                <UsernameInput1
                  ref={register({ required: true })}
                  name={"cardHolderName"}
                  value={cardHolderName.value}
                  onChange={(event) =>
                    setCardHolderName({ value: event.target.value })
                  }
                  placeholder="Roberto Damasceno"
                  // secureTextEntry={false}
                ></UsernameInput1>
              </Rect1>
              {errors.cardHolderName && (
                <div>
                  <span>Nome é obrigatório</span>
                </div>
              )}
            </div>

            <div className="mb-3">
              <Group>
                <EvilIconsIcon
                  name="credit-card"
                  style={{
                    color: "rgba(155,155,155,1)",
                    fontSize: 30,
                    marginLeft: 20,
                    marginTop: 17,
                  }}
                ></EvilIconsIcon>
                <TextInput
                  ref={register({ required: true })}
                  value={cardNumber.value}
                  name="cardNumber"
                  onChange={(event) =>
                    setCardNumber({ value: event.target.value })
                  }
                  placeholder="5152603879891105"
                  // secureTextEntry={false}
                ></TextInput>
              </Group>
              {errors.cardNumber && (
                <div>
                  <span>Número do cartão é obrigatório</span>
                </div>
              )}
            </div>

            <div className="mb-3">
              <Group2>
                <EvilIconsIcon
                  name="lock"
                  style={{
                    color: "rgba(155,155,155,1)",
                    fontSize: 30,
                    marginLeft: 20,
                    marginTop: 17,
                  }}
                ></EvilIconsIcon>
                <TextInput2
                  ref={register({ required: true })}
                  name="cardCVV"
                  value={cardCVV.value}
                  onChange={(event) =>
                    setCardCVV({ value: event.target.value })
                  }
                  placeholder="CVV"
                  // secureTextEntry={false}
                  maxLength={3}
                ></TextInput2>
              </Group2>
              {errors.cardCVV && (
                <div>
                  <span>CVV é obrigatório</span>
                </div>
              )}
            </div>

            <div className="mb-3">
              <Group3>
                <EvilIconsIcon
                  name="clock"
                  style={{
                    color: "rgba(155,155,155,1)",
                    fontSize: 30,
                    marginLeft: 20,
                    marginTop: 17,
                  }}
                ></EvilIconsIcon>
                <InputMask
                  inputRef={register({ required: true })}
                  className="input-masked"
                  name="cardExpirationDate"
                  value={cardExpirationDate.value}
                  onChange={(event) =>
                    setCardExpirationDate({ value: event.target.value })
                  }
                  placeholder="02/22"
                  // secureTextEntry={false}
                  mask="(99/99)"
                ></InputMask>
              </Group3>
              {errors.cardExpirationDate && (
                <div>
                  <span>Data de validade é obrigatória</span>
                </div>
              )}
            </div>

            <div className="mb-3">
              <Group4>
                <FontAwesomeIcon
                  name="id-card-o"
                  style={{
                    color: "rgba(155,155,155,1)",
                    fontSize: 20,
                    width: 34,
                    height: 30,
                    marginLeft: 20,
                    marginTop: 17,
                  }}
                ></FontAwesomeIcon>
                <InputMask
                  inputRef={register({ required: true, validate:{"isValidCpfteste": validateCpfNumber}})}
                  className="input-masked"
                  name="cardHolderCpf"
                  value={cardHolderCpf.value}
                  onChange={(event) =>
                    setCardHolderCpf({ value: event.target.value })
                  }
                  placeholder="CPF"
                  // secureTextEntry={false}
                  mask="999.999.999-99"
                ></InputMask>
              </Group4>
              {errors.cardHolderCpf && (
                <div>
                  <span>CPF é obrigatório</span>
                </div>
              )}
            </div>
          </Group5>
          <ImageRow className="mb-4">
            <Image src={require("../../assets/images/image_CqvV..png")}></Image>
            <LoremIpsum>
              Ambiente Seguro de pagamento de Cartão{"\n"}Este é um ambiente
              seguro 2048-bit SSL encrypted payment.
            </LoremIpsum>
          </ImageRow>

          <Button1 className="mt-2 ml-1" onClick={() => onSubmitCard()}>
            <button
              style={{
                backgroundColor: "#1193ff",
                color: "white",
                height: "50px",
              }}
              className="btn mb-3 col-md-11 col-11 ml-3"
            >
              Aceitar
            </button>
          </Button1>
        </form>
      </PlanosColumn>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  background-color: rgba(238, 243, 255, 1);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ButtonOverlay = styled.button`
  display: block;
  background: none;
  height: 100%;
  width: 100%;
  border: none;
`;
const Planos = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  font-size: 20px;
  margin-left: 8px;
`;

const Group5 = styled.div`
  width: 305px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 27px;
  margin-left: 27px;
  display: flex;
`;

const Rect1 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const UsernameInput1 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const TextInput = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group2 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const TextInput2 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group3 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const TextInput3 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Group4 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const TextInput4 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 7px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  height: 31px;
  width: auto;
  margin-right: 5px;
`;

const LoremIpsum = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  font-size: 11px;
  margin-top: 5px;
`;

const ImageRow = styled.div`
  height: 31px;
  flex-direction: row;
  display: flex;
  margin-top: 27px;
  margin-left: 27px;
  width: 305px;
`;

const PlanosColumn = styled.div`
  flex-direction: column;
  margin-top: 18px;
  margin-right: 12px;
  display: flex;
`;

const PlanosColumnFiller = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
`;

const Button1 = styled.div`
  width: 345px;
  height: 54px;
  flex-direction: column;
  display: flex;
  border: none;
`;

const ButtonFrame1 = styled.div`
  width: 360px;
  height: 70px;
  background-color: rgba(17, 147, 255, 1);
  flex-direction: column;
  display: flex;
  box-shadow: 0px -2px 2px 1px rgba(0, 0, 0, 1);
`;

const Aceitar1 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  font-size: 30px;
  margin-top: 10px;
  margin-left: 133px;
`;

export default CartPaymentInformation; */
