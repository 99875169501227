import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HeaderX from "./components/HeaderX";
import "./icons.js";
import CartCongrats from "./screens/CartCongrats";
import CartCustomerLead from "./screens/CartCustomerLead";
import CartCustomerProfile from "./screens/CartCustomerProfile";
import CartPaymentInformation from "./screens/CartPaymentInformation";
import CustomerInfo from "./screens/CustomerInfo/CustomerInfo";
import Feedback from "./screens/Feedback";
import Login from "./screens/Login";
import PlanDetails from "./screens/PlanDetails";
import PlanList from "./screens/PlanList";
import ServiceRequestConfirmation from "./screens/ServiceRequestConfirmation";
import Settings from "./screens/Settings";
import SignUp from "./screens/SignUp/index";
import SingUpConfirmation1 from "./screens/SingUpConfirmation1";
import { isUserLogged } from "./services/partnerService";
import {
  isCustomerLogged
} from "./services/customerService";
import "./style.css";

// window.addEventListener("beforeunload", async (ev) => {
//   debugger
  
//   ev.preventDefault();
//   await handleSignOutCustomer(ev.target.URL);
// });

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isUserLogged() ? (
        <div>
          <HeaderX></HeaderX>
          <Component {...props} />
        </div>
      ) : (
        <Redirect to={{ pathName: "/", state: { from: props.location } }} />
      )
    }
  />
);

const PrivateRouteCustomer = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isCustomerLogged() ? (
        <div>
          <Component {...props} />
        </div>
      ) : (
        <Redirect to={{ pathName: "/CartCustomerLead/", state: { from: props.location } }} />
      )
    }
  />
);

function App() {
  return (
    <div>
      <Router>
        <Route path="/" exact component={Login} />
        <PrivateRoute path="/CustomerInfo/" exact component={CustomerInfo} />
        <Route path="/Feedback/" exact component={Feedback} />
        <Route path="/Login/" exact component={Login} />
        <PrivateRoute path="/PlanDetails/" exact component={PlanDetails} />
        <PrivateRoute path="/PlanList/" exact component={PlanList} />
        <PrivateRoute
          path="/ServiceRequestConfirmation/"
          exact
          component={ServiceRequestConfirmation}
        />
        <PrivateRoute path="/Settings/" exact component={Settings} />
        <PrivateRouteCustomer path="/CartCongrats/" exact component={CartCongrats} />

        <Route path="/CartCustomerLead/" exact component={CartCustomerLead} />
        <PrivateRouteCustomer
          path="/CartCustomerProfile/"
          exact
          component={CartCustomerProfile}
        />
        <PrivateRouteCustomer
          path="/CartPaymentInformation/"
          exact
          component={CartPaymentInformation}
        />

        <Route path="/SignUp/" exact component={SignUp} />
        <Route
          path="/SingUpConfirmation1/"
          exact
          component={SingUpConfirmation1}
        />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
