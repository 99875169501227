import {toast} from 'react-toastify';



const defaultNotificationConfig = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const notifyError = (mesage) => toast.error(mesage, defaultNotificationConfig);

const notifyWarn = (mesage) => toast.warn(mesage, defaultNotificationConfig);


export {notifyError, notifyWarn};