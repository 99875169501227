import Bugsnag from "@bugsnag/js";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import EvilIconsIcon from "react-native-vector-icons/dist/EvilIcons";
import FeatherIcon from "react-native-vector-icons/dist/Feather";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { isEmailValid } from "../../common/validator/common.validations";
import { setBodyBackGroundColor } from "../../services/commonService";
import {
  _createUserWithEmailAndPassword,
  _save,
  _sendVerificationEmail
} from "../../services/firebaseSevice";
import { notifyError } from "../../services/noticationService";
import "./style.css";

function SignUp() {
  const [name, setName] = useState({});
  const [phone, setPhone] = useState({});
  const [email, setEmail] = useState({});
  const [password, setPassword] = useState({});
  const history = useHistory();

  setBodyBackGroundColor("rgba(74,74,74,1)");

  const submit = async () => {
    if (!isValidToSubmit()) return;

    try {
      const responseUser = await _createUserWithEmailAndPassword(
        email.value,
        password.value
      );
      const splitedName = name.value.split(" ");
      const payload = {
        firstName: splitedName[0],
        lastName: splitedName[splitedName.length - 1],
        phone: phone.value.replace(/[^\d]+/g, ""),
        userId: responseUser.user.uid,
      };
      await _save("usersAdditionalInfo", payload);
      await _sendVerificationEmail();
      history.push("/SingUpConfirmation1");
    } catch (error) {
      if (error.code === 500) {
        Bugsnag.notify(new Error(error));
        notifyError("Ocorreu um erro ao salvar os dados");
      } else {
        notifyError(error.message);
      }
    }
  };

  const isValidToSubmit = () => {
    let isValid = true;
    if (name.value === undefined || name.value.trim() === "") {
      setName({ error: "Nome Completo é obrigatório" });
      isValid = false;
    } else {
      const splitedName = name.value.split(" ");
      if (
        splitedName.length <= 1 ||
        splitedName[splitedName.length - 1] === ""
      ) {
        setName({ error: "Favor digitar o nome completo" });
        isValid = false;
      }
    }

    if (phone.value === undefined || phone.value.trim() === "") {
      setPhone({ error: "Celular é obrigatório" });
      isValid = false;
    }

    if (email.value === undefined || email.value.trim() === "") {
      setEmail({ error: "Email é obrigatório" });
      isValid = false;
    } else {
      const emailValid = isEmailValid(email.value);
      if (!emailValid.isValid) {
        setEmail({ error: emailValid.message });
        isValid = false;
      }
    }

    if (password.value === undefined || password.value.trim() === "") {
      setPassword({ error: "Senha é obrigatório" });
      isValid = false;
    }
    return isValid;
  };

  return (
    <Root id="teste">
      <Background>
        <Rect2>
          <CriarUmaContaColumn className="align-items-center col-12 col-md-auto">
            <CriarUmaConta>CRIAR UMA CONTA</CriarUmaConta>
            <Form className="col-md-7">
              <Group>
                <div>
                  <label className="label-fields-sign-up">Nome Completo *</label>
                  <Name>
                    <EvilIconsIcon
                      name="user"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 33,
                        width: 33,
                        height: 33,
                        marginLeft: 15,
                        alignSelf: "center",
                      }}
                    ></EvilIconsIcon>
                    <NameInput
                      value={name.value}
                      onChange={(event) =>
                        setName({ value: event.target.value })
                      }
                      placeholder="Jose Aldo dos Santos"
                      secureTextEntry={false}
                    ></NameInput>
                  </Name>
                  <div className="mb-3 message-validation">{name.error}</div>
                </div>
                <div>
                  <label className="label-fields-sign-up">Celular *</label>
                  <Phone>
                    <FeatherIcon
                      name="phone"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 28,
                        marginLeft: 15,
                        marginTop: 13,
                      }}
                    ></FeatherIcon>
                    <InputMask
                      className="input-telefone"
                      mask="(99) 99999-9999"
                      value={phone.value}
                      onChange={(event) =>
                        setPhone({ value: event.target.value })
                      }
                      secureTextEntry={false}
                      placeholder="(99) 99999-9999"
                    ></InputMask>
                  </Phone>
                  <div className="mb-3 message-validation">{phone.error}</div>
                </div>
                <div>
                  <label className="label-fields-sign-up">Email *</label>
                  <Email>
                    <EvilIconsIcon
                      name="envelope"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 33,
                        marginLeft: 15,
                        alignSelf: "center",
                      }}
                    ></EvilIconsIcon>
                    <EmailInput
                      value={email.value}
                      onChange={(event) =>
                        setEmail({ value: event.target.value })
                      }
                      placeholder="jose.aldo@gmail.com"
                      secureTextEntry={false}
                    ></EmailInput>
                  </Email>
                  <div className="mb-3 message-validation">{email.error}</div>
                </div>
                <div>
                  <label className="label-fields-sign-up">Senha *</label>
                  <Password>
                    <EvilIconsIcon
                      name="lock"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 33,
                        marginLeft: 15,
                        marginTop: 13,
                      }}
                    ></EvilIconsIcon>
                    <PasswordInput
                      type="password"
                      value={password.value}
                      onChange={(event) =>
                        setPassword({ value: event.target.value })
                      }
                      placeholder="Senha"
                    ></PasswordInput>
                  </Password>
                  <div className="mb-3 message-validation">{password.error}</div>
                </div>
              </Group>
            </Form>
            <ButtonColumn className="col-md-7 mt-4">
              <Button className="mb-4" onClick={submit}>
                <ButtonOverlay>
                  <Text2>Enviar</Text2>
                </ButtonOverlay>
              </Button>
              <TermosCondicoes>Termos &amp; Condições</TermosCondicoes>
            </ButtonColumn>
          </CriarUmaContaColumn>
        </Rect2>
      </Background>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  background: transparent;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ButtonOverlay = styled.button`
  display: block;
  background: none;
  height: 100%;
  width: 100%;
  border: none;
`;
const Background = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Rect2 = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const CriarUmaConta = styled.span`
  font-family: Arial;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: bold;
`;

const Form = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 22px;
`;

const Group = styled.div`
  flex-direction: column;
  justify-content: space-between;
  display: flex;
`;

const Name = styled.div`
  height: 59px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  align-self: stretch;
  flex-direction: row;
  display: flex;
`;

const NameInput = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 13px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: black;
`;

const Phone = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  color: black;
`;

const Email = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
`;

const EmailInput = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(255, 255, 255, 1);
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 13px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: black;
`;

const Password = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
`;

const PasswordInput = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(255, 255, 255, 1);
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 13px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: black;
`;

const CriarUmaContaColumn = styled.div`
  flex-direction: column;
  padding-top: 10vh;
  display: flex;
`;

const Button = styled.div`
  height: 55px;
  background-color: rgba(43, 191, 84, 1);
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border: none;
`;

const Text2 = styled.span`
  font-family: Arial;
  width: 66px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  align-self: center;
`;

const TermosCondicoes = styled.span`
  font-family: Arial;
  color: rgba(255, 255, 255, 0.5);
`;

const ButtonColumn = styled.div`
  flex-direction: column;
  margin-bottom: 31px;
  margin-left: 41px;
  margin-right: 41px;
  display: flex;
`;

export default SignUp;
