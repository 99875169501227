import Bugsnag from "@bugsnag/js";
import firebase from "../config/firebase";
// import { setUserAsLogged } from "./partnerService";

const db = firebase.firestore();
db.settings({
  timestempsInSnapshots: true,
});

const _save = async (collection, payload) => {
  try {
    await db.collection(collection).add(payload);
  } catch (error) {
    Bugsnag.notify(new Error(error));
  }
};

const _createUserWithEmailAndPassword = async (email, password) => {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
};

const _sendVerificationEmail = async () => {
  const user = firebase.auth().currentUser;
  return await user.sendEmailVerification();
};

const _getDataByCpfAndToken = async (cpf, token) => {
  try {
    const response = await db
      .collection("customerPlanData")
      .where("cpf", "==", cpf)
      .where("accessToken", "==", token)
      .get();
    return response;
  } catch (error) {
    Bugsnag.notify(new Error(error));
  }
};

const _signInWithEmailAndPassword = async (email, password) => {
  return await firebase.auth().signInWithEmailAndPassword(email, password)
  // .then((userCredential) => {
  //   // Signed in
  //   _saveUserDataToLocalStorage();
  //   setUserAsLogged()
  //   return userCredential
  // })
  // .catch((error) => {
  //   var errorCode = error.code;
  //   var errorMessage = error.message;
  //   // ..
  // });
};

const _signOut = async () => {
  return await firebase.auth().signOut();
};

const _updateCollectionDataById = async (_id, collection, payload) => {
  const docRef = await db.collection(collection).doc(_id);
  try {
    await docRef.update(payload);
  } catch (error) {
    Bugsnag.notify(new Error(error));
  }
};

const _saveUserDataToLocalStorage = async () => {
  const userData = {
    userName: "",
    userEmail: "",
  };

  const currentUser = firebase.auth().currentUser;

  await  db.collection("usersAdditionalInfo")
    .where("userId", "==", currentUser.uid)
    .get()
    .then((response) => {
      console.log("response: " + JSON.stringify(response));
      // let [result] = response;
      // userData.userName =
      //   result.data().firstName + " " + result.data().lastName;
      // userData.userEmail = currentUser.email;
      // localStorage.setItem("userData", JSON.stringify(userData));
       response.forEach((item) => {
        console.log('item: ' + item)
        userData.userName = item.data().firstName + " " + item.data().lastName;
        userData.userEmail = currentUser.email;
        localStorage.setItem("userData", JSON.stringify(userData));
        
      });
    });
    // return userData;
};

const _signInAnonymously = async () => {
  try {
    await firebase.auth().signInAnonymously();
  } catch (error) {
    Bugsnag.notify(new Error(error));
  }
};

const _signOutCustomer = async () => {
  const user = firebase.auth().currentUser;
  await user.delete();
};

export {
  _save,
  _createUserWithEmailAndPassword,
  _sendVerificationEmail,
  _getDataByCpfAndToken,
  _signInWithEmailAndPassword,
  _signOut,
  _updateCollectionDataById,
  _saveUserDataToLocalStorage,
  _signInAnonymously,
  _signOutCustomer,
};
