import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import EvilIconsIcon from "react-native-vector-icons/dist/EvilIcons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { setBodyBackGroundColor } from "../../services/commonService";
import { setCustomerAsLogged } from "../../services/customerService";
import {
  _getDataByCpfAndToken,
  _signInAnonymously,
  _updateCollectionDataById,
} from "../../services/firebaseSevice";
import { notifyError, notifyWarn } from "../../services/noticationService";
import "./styles.css";

function CartCustomerLead() {
  const [cpf, setCpf] = useState({value: ''});
  const [password, setPassword] = useState({value: ''});
  const history = useHistory();
  const { errors, register, trigger } = useForm({
    mode: "onBlur",
  });

  setBodyBackGroundColor("rgba(255, 255, 255, 1)");

  const onLogin = async () => {
    try {
      trigger(["cpf", "password"]);
      if (Object.keys(errors).length === 0) {
        await _signInAnonymously();

        const response = await _getDataByCpfAndToken(
          cpf.value.replace(/[^\d]+/g, ""),
          password.value
        );
        if (response.empty) {
          notifyError("Nenhum dado encontrado para o cpf/senha");
          return;
        }

        let user_id = "";
        let isFinished = false;

        response.forEach((item) => {
          if (item.data().isFinished) {
            isFinished = true;
          } else {
            const customerPlanData = {
              ...item.data(),
              _id: item.id,
            };

            localStorage.setItem(
              "customerPlanData",
              JSON.stringify(customerPlanData)
            );
            user_id = item.id;
          }
        });

        if (isFinished) {
          notifyWarn(
            "Não foi encontrada nenhuma proposta pendente de aprovação"
          );
          return;
        }

        const collectionName = "customerPlanData";
        const payload = {
          loginAt: new Date(),
        };

        await _updateCollectionDataById(user_id, collectionName, payload);

        setCustomerAsLogged();
        history.push("/CartCustomerProfile");
      } else {
        notifyWarn("Preencha todos os campos corretamente");
      }
    } catch (error) {
      notifyError("Ocorreu ao fazer o login");
    }
  };

  return (
    <Root>
      <Image />
      <Form>
        <Rect8Column>
          <div>
            <Rect8>
              <EvilIconsIcon
                name="user"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 30,
                  marginLeft: 20,
                  marginTop: 17,
                }}
              ></EvilIconsIcon>
              <InputMask
                inputRef={register({ required: true })}
                name="cpf"
                className="input-masked"
                value={cpf.value}
                onChange={(event) => setCpf({ value: event.target.value })}
                placeholder="CPF"
                mask="999.999.999-99"
              ></InputMask>
            </Rect8>
            {errors.cpf && (
              <div>
                <span>Número do cpf é obrigatório</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <Password>
              <EvilIconsIcon
                name="lock"
                style={{
                  color: "rgba(155,155,155,1)",
                  fontSize: 33,
                  marginLeft: 20,
                  alignSelf: "center",
                }}
              ></EvilIconsIcon>
              <PasswordInput
                value={password.value}
                onChange={(event) => setPassword({ value: event.target.value })}
                placeholder="Senha"
                type="password"
                name="password"
                ref={register({ required: true })}
              ></PasswordInput>
            </Password>
            {errors.password && (
              <div>
                <span>Senha é obrigatória</span>
              </div>
            )}
          </div>
        </Rect8Column>
        <Rect8ColumnFiller></Rect8ColumnFiller>
        <Button onClick={onLogin}>
          <ButtonOverlay>
            <Text>Login</Text>
          </ButtonOverlay>
        </Button>
      </Form>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ButtonOverlay = styled.button`
  display: block;
  background: none;
  height: 100%;
  width: 100%;
  border: none;
`;
const Form = styled.div`
  height: 230px;
  flex-direction: column;
  display: flex;
  padding: 0px 10%;
  @media (min-width: 600px) {
    padding: 0px 20%;
  }
  transition: 0.4s;
`;

const Rect8 = styled.div`
  height: 59px;
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  flex-direction: row;
  display: flex;
  border-style: solid;
`;

const Password = styled.div`
  height: 59px;
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  flex-direction: row;
  display: flex;
  margin-top: 27px;
  border-style: solid;
`;

const PasswordInput = styled.input`
  font-family: Arial;
  height: 30px;
  width: 100%;
  color: rgba(155, 155, 155, 1);
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 8px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Rect8Column = styled.div`
  flex-direction: column;
  display: flex;
`;

const Rect8ColumnFiller = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
`;

const Button = styled.div`
  height: 59px;
  background-color: rgba(43, 191, 84, 1);
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  border: none;
`;

const Text = styled.span`
  color: white;
  font-family: Arial;
  font-weight: 600;
  margin-top: 21px;
  align-self: center;
`;

const Image = styled.div`
  width: 300px;
  flex-direction: column;
  display: flex;
  align-self: center;
  background-image: url(${require("../../assets/images/zk-suporte-tecnico.webp")});
  background-position-y: 34%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 227px;
`;

export default CartCustomerLead;
