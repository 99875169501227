import Bugsnag from "@bugsnag/js";
import axios from "axios";
import Constants from "../utils/constants";
import Endpoints from "../utils/endpoints";

const userLocalStorageKey = "YVhOVmMyVnlRWFYwYUdWdWRHbGpZWFJsWkE9PQ==";


const isUserLogged = () => {
    return JSON.parse(localStorage.getItem(userLocalStorageKey));
};
  
const setUserAsLogged = () => {
    localStorage.setItem(userLocalStorageKey, true);
};

const setUserAsSignOut = () => {
    localStorage.setItem(userLocalStorageKey, false);
    localStorage.setItem("userData", null);
};

const sendEmailSalesPartner = async (receiver, data) => {
    const urlEmail = Constants.apiZentekBaseUrl + Endpoints.emailSalesPartner;
    const payload = {
        receiver: receiver,
        customerFirstName: data.customerFirstName,
        salesParterFirstName: data.salesParterFirstName,
        companyName: data.companyName,
        url: data.url,
        accessToken: data.accessToken,
    };

    try {
        const responseEmail = axios.post(urlEmail, payload);
        if (!responseEmail.data.result.successCode)
        throw Error(responseEmail.data.result.msg);
    } catch (error) {
        Bugsnag.notify(error);
    }
};


export {
    isUserLogged,
    setUserAsLogged,
    setUserAsSignOut,
    sendEmailSalesPartner
}