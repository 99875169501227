import Bugsnag from "@bugsnag/js";
import React, { useState } from "react";
import EvilIconsIcon from "react-native-vector-icons/dist/EvilIcons";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { setBodyBackGroundColor } from "../../services/commonService";
import {
  _saveUserDataToLocalStorage,
  _signInWithEmailAndPassword,
} from "../../services/firebaseSevice";
import { notifyError, notifyWarn } from "../../services/noticationService";
import { setUserAsLogged } from "../../services/partnerService";
import "./style.css";

function Login() {
  const [email, setEmail] = useState({});
  const [password, setPassword] = useState({});
  const history = useHistory();

  setBodyBackGroundColor("rgba(74,74,74,1)");

  const onRequestLogin = async () => {
    if (!isValidToLogin()) return;

    try {
      const responseUser = await _signInWithEmailAndPassword(
        email.value,
        password.value
      );
      console.log(responseUser);

      if (responseUser.user.emailVerified) {
        await _saveUserDataToLocalStorage();
        setUserAsLogged()
        history.push("/PlanList");
      } else {
        notifyWarn(
          "Seu e-mail ainda não foi verificado. Te enviamos um e-mail para que possa verifica-lo!"
        );
      }
    } catch (error) {
      Bugsnag.notify(new Error(error.message));
      notifyError("Email ou senha incorretos");
    }
  };


  const isValidToLogin = () => {
    let isValid = true;
    if (email.value === undefined || email.value.trim() === "") {
      setEmail({ error: "Email is required" });
      isValid = false;
    }

    if (password.value === undefined || password.value.trim() === "") {
      setPassword({ error: "Password is required" });
      isValid = false;
    }
    return isValid;
  };

  return (
    <Root>
      <BackgroundStack>
        <Main>
          <Containner>
            <Logo className="text-center pb-5">
              <ZentekConsultorFiller></ZentekConsultorFiller>
              <h1 className="title">ZENTEK{"\n"}Consultor</h1>
            </Logo>
            <Form>
              <Rect8Column className="col-md-7 m-auto">
                <div className="mb-4">
                  <label className="label-fields-login">Email *</label>
                  <Rect8>
                    <EvilIconsIcon
                      name="envelope"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 30,
                        marginLeft: 20,
                        marginTop: 17,
                      }}
                    ></EvilIconsIcon>
                    <UsernameInput
                      value={email.value}
                      onChange={(event) =>
                        setEmail({ value: event.target.value })
                      }
                      placeholder="Email"
                      secureTextEntry={false}
                    ></UsernameInput>
                  </Rect8>
                  <div className="message-validation">{email.error}</div>
                </div>
                <div>
                  <label className="label-fields-login">Senha *</label>
                  <Password>
                    <EvilIconsIcon
                      name="lock"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 33,
                        marginLeft: 20,
                        alignSelf: "center",
                      }}
                    ></EvilIconsIcon>
                    <PasswordInput
                      type="password"
                      value={password.value}
                      onChange={(event) =>
                        setPassword({ value: event.target.value })
                      }
                      placeholder="Senha"
                      secureTextEntry={false}
                    ></PasswordInput>
                  </Password>
                  <div className="message-validation">{password.error}</div>
                </div>
                <div className="pt-5 text-center">
                  <button
                    className="btn btn-submit col-md-12"
                    onClick={onRequestLogin}
                  >
                    Login
                  </button>
                </div>
              </Rect8Column>
              <Rect8ColumnFiller></Rect8ColumnFiller>
            </Form>
            <div className="col-12 col-sm-7 ml-sm-auto mr-sm-auto mt-4 mt-sm-5 row">
              <Link to="/SignUp" className="col-7 col-md-6 col-sm-6">
                <Button2 className="col-sm-11">
                  <ButtonOverlay className="text-left">
                    <CreateAccountFiller></CreateAccountFiller>
                    <CreateAccount>Criar uma Conta</CreateAccount>
                  </ButtonOverlay>
                </Button2>
              </Link>
              <Button2Filler></Button2Filler>
              <div className="col-5 col-md-6 text-right">
                <PrecisoDeAjuda className="col-6">
                  Preciso de Ajuda ?
                </PrecisoDeAjuda>
              </div>
            </div>
          </Containner>
        </Main>
      </BackgroundStack>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Main = styled.div`
  overflow: hidden;
  flex-direction: column;
  display: flex;
`;

const Containner = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const ButtonOverlay = styled.button`
  display: block;
  background: none;
  height: 100%;
  width: 100%;
  border: none;
`;

const Form = styled.div``;

const Rect8 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  flex-direction: row;
  display: flex;
`;

const UsernameInput = styled.input`
  font-family: Arial;
  height: 30px;
  width: 100%;
  color: rgba(74, 74, 74, 1);
  flex: 1 1 0%;
  margin-right: 11px;
  margin-left: 11px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Password = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  flex-direction: row;
  display: flex;
`;

const PasswordInput = styled.input`
  font-family: Arial;
  height: 30px;
  width: 100%;
  color: rgba(155, 155, 155, 1);
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 8px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Rect8Column = styled.div`
  flex-direction: column;
  display: flex;
`;

const Rect8ColumnFiller = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
`;

const Button2 = styled.div`
  height: 14px;
  flex-direction: column;
  display: flex;
  align-self: flex-end;
  border: none;
`;

const CreateAccountFiller = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
`;

const CreateAccount = styled.span`
  font-family: Arial;
  color: rgba(255, 255, 255, 0.5);
`;

const Button2Filler = styled.div`
  flex: 1 1 0%;
  flex-direction: row;
  display: flex;
`;

const PrecisoDeAjuda = styled.span`
  font-family: Arial;
  color: rgba(255, 255, 255, 0.5);
  align-self: flex-end;
  margin-right: -1px;
`;

const Logo = styled.div``;

const ZentekConsultorFiller = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
`;

const BackgroundStack = styled.div`
  padding-top: 10vh;
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

export default Login;
