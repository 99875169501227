import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { notifyError } from "../../services/noticationService";
import { getValueCurrencyFormatted } from "../../utils/commonFunctions";
import Constants from "../../utils/constants";
import Endpoints from "../../utils/endpoints";
import "./style.css";

function PlanDetails() {
  const history = useHistory();

  const [planList, setPlanList] = useState([]);
  const [baselineRate, setBaseLineRate] = useState(0);
  const [additionalEquipament, setAdditionalEquipament] = useState("");
  const [qtdEquipament, setQtdEquipament] = useState(0);
  const [feePerEquipament, setFeePerEquipament] = useState();
  const [valueTotalEquipament, setValueTotalEquipament] = useState(0);
  const [valuePlanSelected, setValuePlanSelected] = useState(0);
  const [valueTotalPlanSelected, setValueTotalPlanSelected] = useState(0);
  const [planDuration, setPlanDuration] = useState(0);
  const [planSelected, setPlanSelected] = useState({});
  const [productName, setProductName] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  // setBodyBackGroundColor("rgba(238, 243, 255, 1)");

  useEffect(() => {
    const payload = {
      subscriptionId: localStorage.getItem("subsCriptionId"),
    };
    axios
      .post(
        Constants.customerApiBaseUrl + Endpoints.getSubscriptionById,
        payload
      )
      .then((response) => {
        if(!response.data.result.successCode)
          throw new Error(response.data.result.msg);
          
        const planList = response.data.result.subscriptionData[0].plans;
        const baselineRate =
          response.data.result.subscriptionData[0].feesCharge.baselineRate;
        setPlanList(planList);
        setBaseLineRate(baselineRate);
        setProductName(response.data.result.subscriptionData[0].productName);
      })
      .catch((error) => {
        Bugsnag.notify(new Error(error));
        notifyError("Ocorreu um erro ao buscar os dados");
      });
  }, []);

  const onAddEquipament = () => {
    let qtdTotalequipament = parseInt(additionalEquipament) + qtdEquipament;
    setQtdEquipament(qtdTotalequipament);
    setAdditionalEquipament("");
    const valueTotalEquipament = calcValueTotalEquipament(
      qtdTotalequipament,
      feePerEquipament,
      planDuration
    );
    setValueTotalEquipament(valueTotalEquipament);
    updateValueTotalPlanSelected(valuePlanSelected, valueTotalEquipament);
  };

  const calcValueTotalEquipament = (
    qtdEquipament,
    feePerEquipament,
    planDuration
  ) => {
    let valueTotalEquipament = qtdEquipament * feePerEquipament * planDuration;
    return valueTotalEquipament;
  };

  const updateValueTotalPlanSelected = (
    valueTotalPlan,
    valueTotalEquipament
  ) => {
    setValueTotalPlanSelected(valueTotalPlan + valueTotalEquipament);
  };

  const onChangePlanSelected = (data) => {
    let totalValue = parseFloat(
      (
        (baselineRate - (baselineRate * data.discount) / 100) *
        data.months
      ).toFixed(2)
    );
    setValuePlanSelected(totalValue);
    setValueTotalPlanSelected(totalValue);
    setFeePerEquipament(data.additionalDeviceFees);
    setPlanDuration(data.months);
    setPlanSelected(data);
    if (qtdEquipament > 0) {
      const valueTotalEquipament = calcValueTotalEquipament(
        qtdEquipament,
        data.additionalDeviceFees,
        data.months
      );
      setValueTotalEquipament(valueTotalEquipament);
      updateValueTotalPlanSelected(totalValue, valueTotalEquipament);
    }
  };

  const onChangePaymentMethod = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
  };

  const canShowPaymentMethods = () => {
    return valueTotalPlanSelected / planSelected.months > 1000;
  };

  const onNextStep = () => {
    const valueInstalment = parseFloat(
      (valueTotalPlanSelected / planSelected.months).toFixed(2)
    );

    if (!isValidToContinue(valueInstalment)) return;

    console.log(paymentMethod);
    const payload = {
      paymentMethod: paymentMethod ? paymentMethod : "creditCard",
      productName: productName,
      plan: {
        ...planSelected,
        installment: valueInstalment.toString(),
        devicesTotalValue: valueTotalEquipament.toString(),
        numberDevicesAdded: qtdEquipament,
        planValue: valuePlanSelected.toString(),
        planTotalValue: valueTotalPlanSelected.toString(),
        _id: localStorage.getItem("subsCriptionId"),
      },
    };

    localStorage.setItem("dataPlanSelected", JSON.stringify(payload));
    history.push("/CustomerInfo");
  };

  const isValidToContinue = () => {
    if (!planSelected.months) {
      notifyError("Selecione um plano para prosseguir");
      return false;
    }

    if (canShowPaymentMethods() && !paymentMethod) {
      notifyError("Selecione uma forma de pagamento para prosseguir");
      return false;
    }
    return true;
  };
  return (
    <div id="root">
      <div className="container mb-4">
        <Card className="m-auto">
          <h2 className="product-name pl-4 pt-2">{productName}</h2>
          <Card.Body className="row">
            <div className="row list-plans-container mb-4">
              {planList.map((data, index) => (
                <div className="col-12">
                  <div>
                    <PlanLengthBullet className="row" key={index}>
                      <MonthItem className="col-5">
                        <div className="ml-3">
                          <label className="form-check-label contain">
                            <input
                              className="d-none"
                              name="radioPlan"
                              onChange={() => onChangePlanSelected(data)}
                              type="radio"
                              value=""
                              id={"radioPlan" + index}
                            />
                            <span className="ml-3 text-plan-values font-weight-bold">
                              {data.months} {data.title}
                            </span>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </MonthItem>
                      <div className="col-7">
                        <p className="text-right font-weight-bold text-plan-values">
                          <PlanValue className="text-right col-md-6 font-weight-bold">
                            {getValueCurrencyFormatted(
                              baselineRate -
                                (baselineRate * data.discount) / 100
                            )}
                          </PlanValue>
                          /mês
                        </p>
                        {data.discount === 0 && (
                          <p className="text-right discount-text">
                            O cliente pode cancelar quando quiser!
                          </p>
                        )}
                        {data.discount > 0 && (
                          <div className="text-right">
                            <p className="mb-0 text-plan-values">
                              <s>{getValueCurrencyFormatted(baselineRate)}</s>
                            </p>
                            <p className="mb-0 discount-text">
                              {data.discount}% em desconto para {data.months}/
                              {data.title}
                            </p>
                          </div>
                        )}
                      </div>
                    </PlanLengthBullet>
                    <Divider className="col-sm-12 ml-lg-3 mt-5"></Divider>
                  </div>
                </div>
              ))}

              {canShowPaymentMethods() && (
                <div className="col-md-9 mb-3 ml-3 mt-4">
                  <label className="font-weight-bold">Forma de pagamento</label>
                  <div className="col-md-6 ml-2">
                    <label className="form-check-label contain ml-5">
                      <input
                        className="d-none"
                        name="radioFormaPagamento"
                        value={paymentMethod}
                        onChange={() => onChangePaymentMethod("boleto")}
                        type="radio"
                      />
                      <span className="ml-3">Boleto</span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-md-6 ml-2">
                    <label className="form-check-label contain ml-5">
                      <input
                        className="d-none"
                        name="radioFormaPagamento"
                        value={paymentMethod}
                        onChange={() => onChangePaymentMethod("credit_card")}
                        type="radio"
                      />
                      <span className="ml-3">Cartão de crédito</span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              )}

              {planSelected.months !== undefined && (
                <div className="ml-3 mt-3">
                  <p className="col-md-7 font-italic">
                    Adicione mais equipamentos por apenas
                    {" " + getValueCurrencyFormatted(feePerEquipament)}/mês/cada
                  </p>
                  <input
                    className="ml-3 mb-2"
                    value={additionalEquipament}
                    type="text"
                    id="addEquipament"
                    onChange={(event) =>
                      setAdditionalEquipament(event.target.value)
                    }
                  />
                  <button
                    className="btn btn-custom ml-3"
                    onClick={() => onAddEquipament()}
                  >
                    Adicionar
                  </button>
                </div>
              )}
            </div>

            <Card
              className="card col-11 col-lg-5 col-md-10 col-sm-11 m-auto m-md-0 ml-md-5"
              style={{ height: "500px" }}
            >
              <div className="pt-4">
                <h3 className="font-weight-bold">RESUMO</h3>

                <EnterpriseRow className="col-auto col-md-12 mt-4 row">
                  <div className="col-12 col-md-6 pl-0">
                    <span className="font-weight-bold text-plan-values">
                      {productName}
                    </span>
                  </div>
                  <div className="col-md-6 pl-0 text-md-right">
                    <TotalValue className="font-weight-bold text-plan-values">
                      {getValueCurrencyFormatted(valuePlanSelected)}
                    </TotalValue>
                  </div>
                </EnterpriseRow>

                {qtdEquipament > 0 && (
                  <Enterprise2Row className="col-12 col-md-12 mt-4 row">
                    <div className="col-12 col-md-7 pl-0">
                      <span className="font-weight-bold text-plan-values">
                        + {qtdEquipament} Equipamentos
                      </span>
                    </div>
                    <div className="col-md-5 pl-0 text-md-right">
                      <R700000 className="text-plan-values">
                        {getValueCurrencyFormatted(valueTotalEquipament)}
                      </R700000>
                    </div>
                  </Enterprise2Row>
                )}

                <Divider className="mb-3 ml-md-3"></Divider>

                <Total3Row className="col-md-12  mb-5 ml-md-1 row">
                  <div className="col-md-12 pl-0 text-md-right">
                    <R123900 style={{ fontSize: "15px" }}>
                      {getValueCurrencyFormatted(valueTotalPlanSelected)}
                    </R123900>
                  </div>
                  <div className="col-12 col-3 col-md-4 pl-0">
                    <Total3 className="font-weight-bold text-plan-values">
                      Total
                    </Total3>
                  </div>
                  <div className="col-12 col-9 col-md-6 col-md-8 pl-0 text-md-right">
                    <R123900 className="font-weight-bold">
                      {planDuration}x de
                    </R123900>
                    <MonthlyValue className="text-plan-values">
                      {planDuration === 0
                        ? getValueCurrencyFormatted(0)
                        : getValueCurrencyFormatted(
                            valueTotalPlanSelected / planDuration
                          )}
                    </MonthlyValue>
                  </div>
                </Total3Row>
                <div className="text-center">
                  <button
                    className="btn btn-custom col-md-11"
                    onClick={() => onNextStep()}
                  >
                    Próximo
                  </button>
                </div>
              </div>
            </Card>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

const PlanLengthBullet = styled.div`
  height: 64px;
  flex-direction: column;
  display: flex;
  margin-left: 14px;
  margin-top: 22px;
`;

const MonthItem = styled.div`
  width: 69px;
  height: 26px;
  flex-direction: row;
  display: flex;
`;

const PlanValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  margin-top: 5px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(155, 155, 155, 1);
  margin-top: 10px;
  margin-left: 3px;
`;

const TotalValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  width: 82px;
  height: 16px;
`;

const EnterpriseRow = styled.div`
  flex-direction: row;
  display: flex;
  font-size: 22px;
`;

const R700000 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  width: 82px;
  height: 16px;
`;

const Enterprise2Row = styled.div`
  flex-direction: row;
  display: flex;
  font-size: 22px;
  color: rgba(155, 155, 155, 1);
`;

const Total3 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;

const R123900 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: rgba(155, 155, 155, 1);
  font-size: 12px;
  margin-top: 6px;
`;

const MonthlyValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: rgba(155, 155, 155, 1);
  font-size: 22px;
  margin-left: 6px;
  margin-top: 4px;
`;

const Total3Row = styled.div`
  flex-direction: row;
  display: flex;
  font-size: 22px;
`;

export default PlanDetails;
