import React, { useState } from "react";
import InputMask from "react-input-mask";
import EvilIconsIcon from "react-native-vector-icons/dist/EvilIcons";
import FeatherIcon from "react-native-vector-icons/dist/Feather";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome";
import MaterialIconsIcon from "react-native-vector-icons/dist/MaterialIcons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import firebase from "../../../src/config/firebase";
import {
  isCpfValid,
  isEmailValid,
} from "../../common/validator/common.validations";
import {
  setBodyBackGroundColor,
  _getAddressByZipCode,
} from "../../services/commonService";
import { createCustomer, signInCustomer } from "../../services/customerService";
import { _save } from "../../services/firebaseSevice";
import { notifyError, notifyWarn } from "../../services/noticationService";
import { sendEmailSalesPartner } from "../../services/partnerService";
import Constants from "../../utils/constants";
import "./style.css";

function CustomerInfo() {
  const history = useHistory();
  const [name, setName] = useState({});
  const [cpf, setCPF] = useState({});
  const [phone, setPhone] = useState({});
  const [email, setEmail] = useState({});
  const [companyName, setCompanyName] = useState({});
  const [companyCnpj, setCompanyCnpj] = useState({});
  const [zipCode, setZipCode] = useState({});
  const [street, setStreet] = useState({});
  const [neighborhood, setNeighborhood] = useState({});
  const [city, setCity] = useState({});
  const [state, setState] = useState({});
  const [houseNumber, setHouseNumber] = useState({});
  const [addressComplement, setAddressComplement] = useState({});

  setBodyBackGroundColor("rgba(231, 231, 231, 1)");

  const onSubmit = async () => {
    if (!isValidToSubmit()) {
      notifyWarn("Preencha corretamente todos os campos");
      return;
    }
    const user = firebase.auth().currentUser;
    // let salesPartner_id;
    // if (user != null) {
    //   salesPartner_id = user.uid
    // }

    const accessToken = getAccessToken();
    try {
      const splitedName = name.value.split(" ");
      const payloadCustomer = {
        firstName: splitedName[0],
        lastName: splitedName[splitedName.length - 1],
        email: email.value,
        password: accessToken,
        address: getBillingAddressObject(),
        salesPartner_id: user ? user.uid : "",
      };

      const response = await createCustomer(payloadCustomer);

      const responseSignIn = await signInCustomer({
        password: accessToken,
        email: email.value,
      });

      const payloadCustomerPlanData = getPayloadToSaveCustomerPlanData(
        response.data.result.user_id,
        responseSignIn.data.result.token,
        accessToken
      );

      await _save("customerPlanData", payloadCustomerPlanData);

      await sendEmailToCustomer(
        email.value,
        name.value,
        companyName.value,
        accessToken
      );
      history.push("/ServiceRequestConfirmation");
    } catch (error) {
      notifyError("Ocorreu um erro ao salvar os dados");
    }
  };

  const getPayloadToSaveCustomerPlanData = (customerId, token, accessToken) => {
    const planData = JSON.parse(localStorage.getItem("dataPlanSelected"));
    return {
      ...planData,
      customer_id: customerId,
      customer_token: token,
      name: name.value,
      cpf: cpf.value.replace(/[^\d]+/g, ""),
      phone: phone.value.replace(/[^\d]+/g, ""),
      email: email.value,
      companyName: companyName.value,
      companyCnpj: companyCnpj.value.replace(/[^\d]+/g, ""),
      isFinished: false,
      loginAt: null,
      acceptedAt: null,
      accessToken: accessToken,
      billingAddress: getBillingAddressObject(),
    };
  };

  const getBillingAddressObject = () => {
    return {
      mainAddress: street.value + ", " + houseNumber.value,
      zipCode: zipCode.value,
      country: "br",
      building: parseInt(houseNumber.value),
      city: city.value,
      neighborhood: neighborhood.value,
      neighborhood_short: neighborhood.value,
      secondaryAddress: `${street.value}, ${houseNumber.value}, ${neighborhood.value}- ${state.value}, CEP ${zipCode.value}`,
      state: state.value,
      street: street.value,
    };
  };

  const getAccessToken = () => {
    return Math.random().toString(36).substr(2).substr(1, 7);
  };

  const getAddressByZipCode = async () => {
    const address = await _getAddressByZipCode(zipCode.value);
    setAddress(address);
  };

  const setAddress = (address) => {
    setStreet({ value: address.data.logradouro });
    setNeighborhood({ value: address.data.bairro });
    setCity({ value: address.data.localidade });
    setState({ value: address.data.uf });
  };

  const sendEmailToCustomer = async (email, name, company, accessToken) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const data = {};
    data['customerFirstName'] = name;
    data['salesParterFirstName'] = userData.userName;
    data['companyName'] = company;
    data['url'] = Constants.customerLoginUrl;
    data['accessToken'] = accessToken;



    // const mailBody = `
    //   <body>Olá, ${name}!
    //     <p>A ZENTEK ficamos muito felizes em ter você conosco.</p>

    //     <p>
    //       Uma proposta feita pelo Consultor ${userData.userName} para a empresa <b>${company}</b> 
    //       foi iniciada e você deve dar continuidade ao processo.
    //       Verificar os dados inseridos pelo Consultor é muito importante.
    //     </p>
        
    //     <p>A proposta só terá continuidade após a confirmação das informações.</p>

    //     <p>
    //       Clique em 
    //       <a href="${Constants.customerLoginUrl}">minha proposta</a> 
    //       e use seu CPF para usuário e a senha <b>${accessToken}</b> para se autenticar.
    //     </p>
    //     Atenciosamente,
    //     Zentek Tecnologia.
    //   </body>`;

    await sendEmailSalesPartner(email, data);
  };

  const isValidToSubmit = () => {
    let isValid = true;
    if (name.value === undefined || name.value.trim() === "") {
      setName({ error: "Nome Completo é obrigatório" });
      isValid = false;
    }

    if (cpf.value === undefined || cpf.value.trim() === "") {
      setCPF({ error: "CPF é obrigatório" });
      isValid = false;
    } else {
      const cpfValid = isCpfValid(cpf.value);
      if (!cpfValid) {
        setCPF({ value: cpf.value, error: "Favor digitar um CPF válido" });
        isValid = false;
      }
    }

    if (phone.value === undefined || phone.value.trim() === "") {
      setPhone({ error: "Celular é obrigatório" });
      isValid = false;
    }

    if (email.value === undefined || email.value.trim() === "") {
      setEmail({ error: "Email é obrigatório" });
      isValid = false;
    } else {
      const emailValid = isEmailValid(email.value);
      if (!emailValid.isValid) {
        setEmail({ value: email.value, error: emailValid.message });
        isValid = false;
      }
    }

    if (companyName.value === undefined || companyName.value.trim() === "") {
      setCompanyName({ error: "Nome da empresa é obrigatório" });
      isValid = false;
    }

    if (companyCnpj.value === undefined || companyCnpj.value.trim() === "") {
      setCompanyCnpj({ error: "CNPJ é obrigatório" });
      isValid = false;
    } else {
      // debugger
      // const cnpjValid = isCnpjValid(companyCnpj.value);
      // if(!cnpjValid){
      //   setCompanyCnpj({value: companyCnpj.value, error: "Favor digitar um CNPJ válido"});
      //   isValid = false;
      // }
    }

    if (zipCode.value === undefined || zipCode.value.trim() === "") {
      setZipCode({ error: "CEP é obrigatório" });
      isValid = false;
    }

    if (street.value === undefined || street.value.trim() === "") {
      setStreet({ error: "Logradouro é obrigatório" });
      isValid = false;
    }

    if (neighborhood.value === undefined || neighborhood.value.trim() === "") {
      setNeighborhood({ error: "Bairro é obrigatório" });
      isValid = false;
    }

    if (city.value === undefined || city.value.trim() === "") {
      setCity({ error: "Cidade é obrigatório" });
      isValid = false;
    }

    if (state.value === undefined || state.value.trim() === "") {
      setState({ error: "UF é obrigatório" });
      isValid = false;
    }

    if (houseNumber.value === undefined || houseNumber.value.trim() === "") {
      setHouseNumber({ error: "Número é obrigatório" });
      isValid = false;
    }

    return isValid;
  };

  return (
    <Root>
      <ScrollArea>
        <Frame>
          <Container className="align-items-center">
            {/* <div className="col-md-7 m-auto"> */}
            <div className="col-md-7 mb-5">
              <h5>Dados Pessoais</h5>
              <div className="mb-3 col-lg-9">
                <label>Nome Completo *</label>
                <Name1>
                  <EvilIconsIcon
                    name="user"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 33,
                      width: 33,
                      height: 33,
                      marginLeft: 15,
                      alignSelf: "center",
                    }}
                  ></EvilIconsIcon>
                  <NameInput1
                    value={name.value}
                    onChange={(event) => setName({ value: event.target.value })}
                    placeholder="Jose Aldo dos Santos"
                    secureTextEntry={false}
                  ></NameInput1>
                </Name1>
                <div>{name.error}</div>
              </div>
              <div className="mb-3 col-md-5 col-lg-5">
                <label>CPF *</label>
                <Group2>
                  <MaterialIconsIcon
                    name="perm-identity"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 25,
                      marginLeft: 19,
                      marginTop: 13,
                    }}
                  ></MaterialIconsIcon>
                  <InputMask
                    className="maskedInput"
                    value={cpf.value}
                    onChange={(event) => setCPF({ value: event.target.value })}
                    placeholder="CPF"
                    mask="999.999.999-99"
                  ></InputMask>
                </Group2>
                <div>{cpf.error}</div>
              </div>

              <div className="mb-3 col-md-9 col-lg-9">
                <label>Email *</label>
                <Email1>
                  <EvilIconsIcon
                    name="envelope"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 33,
                      marginLeft: 15,
                      alignSelf: "center",
                    }}
                  ></EvilIconsIcon>
                  <EmailInput1
                    value={email.value}
                    onChange={(event) =>
                      setEmail({ value: event.target.value })
                    }
                    placeholder="jose.aldo@gmail.com"
                    secureTextEntry={false}
                  ></EmailInput1>
                </Email1>
                <div>{email.error}</div>
              </div>
              <div className="mb-3 col-md-5 col-lg-5">
                <label>Celular *</label>
                <Phone1>
                  <FeatherIcon
                    name="phone"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 25,
                      marginLeft: 20,
                      marginTop: 14,
                    }}
                  ></FeatherIcon>
                  <InputMask
                    className="maskedInput"
                    value={phone.value}
                    onChange={(event) =>
                      setPhone({ value: event.target.value })
                    }
                    placeholder="(99) 99999-9999"
                    mask="(99) 99999-9999"
                    secureTextEntry={false}
                  ></InputMask>
                </Phone1>
                <div>{phone.error}</div>
              </div>
            </div>

            <div className="col-md-7 mb-5">
              <h5>Dados da empresa</h5>
              <div className="mb-3 col-md-9 col-lg-9">
                <label>Nome *</label>
                <NomeEmpresa>
                  <MaterialIconsIcon
                    name="business-center"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 33,
                      marginLeft: 15,
                      marginTop: 13,
                    }}
                  ></MaterialIconsIcon>
                  <PasswordInput1
                    value={companyName.value}
                    onChange={(event) =>
                      setCompanyName({ value: event.target.value })
                    }
                    placeholder="Nome da Empresa"
                  ></PasswordInput1>
                </NomeEmpresa>
                <div>{companyName.error}</div>
              </div>
              <div className="mb-3 col-md-5 col-lg-5">
                <label>CNPJ *</label>
                <CNpj>
                  <FontAwesomeIcon
                    name="building-o"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 25,
                      marginLeft: 22,
                      marginTop: 13,
                    }}
                  ></FontAwesomeIcon>
                  <InputMask
                    className="maskedInput"
                    value={companyCnpj.value}
                    onChange={(event) =>
                      setCompanyCnpj({ value: event.target.value })
                    }
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                  ></InputMask>
                </CNpj>
                <div>{companyCnpj.error}</div>
              </div>
            </div>

            <div className="col-md-7 mb-5">
              <h5>Endereço</h5>
              <div className="mb-3 col-md-4 col-lg-4 ">
                <label>CEP *</label>
                <CEP>
                  <FontAwesomeIcon
                    name="search"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 25,
                      marginLeft: 22,
                      marginTop: 13,
                    }}
                  ></FontAwesomeIcon>
                  <InputMask
                    className="maskedInput"
                    value={zipCode.value}
                    onChange={(event) =>
                      setZipCode({ value: event.target.value })
                    }
                    onBlur={() => getAddressByZipCode()}
                    placeholder="CEP"
                    mask="99999-999"
                  ></InputMask>
                </CEP>
                <div>{zipCode.error}</div>
              </div>
              <div className="mb-3 col-md-9 col-lg-9 ">
                <label>Logradouro *</label>
                <Logradouro>
                  <FontAwesomeIcon
                    name="home"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 25,
                      marginLeft: 22,
                      marginTop: 13,
                    }}
                  ></FontAwesomeIcon>
                  <input
                    className="text-input"
                    value={street.value}
                    onChange={(event) =>
                      setStreet({ value: event.target.value })
                    }
                    placeholder="Logradouro"
                  ></input>
                </Logradouro>
                <div>{street.error}</div>
              </div>
              <div className="row ml-0 mr-0">
                <div className="mb-3 col-lg-6">
                  <label>Cidade *</label>
                  <City>
                    <FontAwesomeIcon
                      name="home"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 25,
                        marginLeft: 22,
                        marginTop: 13,
                      }}
                    ></FontAwesomeIcon>
                    <input
                      className="text-input"
                      value={city.value}
                      onChange={(event) =>
                        setCity({ value: event.target.value })
                      }
                      placeholder="Cidade"
                    ></input>
                  </City>
                  <div>{city.error}</div>
                </div>
                <div className="mb-3 col-md-3">
                  <label>UF *</label>
                  <UF>
                    <FontAwesomeIcon
                      name="home"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 25,
                        marginLeft: 22,
                        marginTop: 13,
                      }}
                    ></FontAwesomeIcon>
                    <input
                      style={{ width: "100%" }}
                      className="text-input"
                      value={state.value}
                      onChange={(event) =>
                        setState({ value: event.target.value })
                      }
                      placeholder="UF"
                    ></input>
                  </UF>
                  <div>{state.error}</div>
                </div>
              </div>
              <div className="mb-3 col-md-4 col-lg-6">
                <label>Bairro *</label>
                <Bairro>
                  <FontAwesomeIcon
                    name="home"
                    style={{
                      color: "rgba(155,155,155,1)",
                      fontSize: 25,
                      marginLeft: 22,
                      marginTop: 13,
                    }}
                  ></FontAwesomeIcon>
                  <input
                    className="text-input"
                    value={neighborhood.value}
                    onChange={(event) =>
                      setNeighborhood({ value: event.target.value })
                    }
                    placeholder="Bairro"
                  ></input>
                </Bairro>
                <div>{neighborhood.error}</div>
              </div>

              <div className="row ml-0 mr-0">
                <div className="mb-3 col-lg-3">
                  <label>Nº *</label>
                  <HouseNumber>
                    <FontAwesomeIcon
                      name="home"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 25,
                        marginLeft: 22,
                        marginTop: 13,
                      }}
                    ></FontAwesomeIcon>
                    <input
                      style={{ width: "100%" }}
                      className="text-input"
                      value={houseNumber.value}
                      onChange={(event) =>
                        setHouseNumber({ value: event.target.value })
                      }
                      placeholder="Número"
                    ></input>
                  </HouseNumber>
                  <div>{houseNumber.error}</div>
                </div>
                <div className="mb-3 col-md-6">
                  <label>Complemento</label>
                  <AddressComplement>
                    <FontAwesomeIcon
                      name="home"
                      style={{
                        color: "rgba(155,155,155,1)",
                        fontSize: 25,
                        marginLeft: 22,
                        marginTop: 13,
                      }}
                    ></FontAwesomeIcon>
                    <input
                      className="text-input"
                      value={addressComplement.value}
                      onChange={(event) =>
                        setAddressComplement({ value: event.target.value })
                      }
                      placeholder="Complemento"
                    ></input>
                  </AddressComplement>
                  <div>{addressComplement.error}</div>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="col-md-12">
                <Button
                  className="btn mb-3 col-md-9 col-lg-9"
                  onClick={() => onSubmit()}
                >
                  Enviar
                </Button>
              </div>
            </div>

            <Group1Filler></Group1Filler>
          </Container>
        </Frame>
      </ScrollArea>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  background-color: rgba(231, 231, 231, 1);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ScrollArea = styled.div`
  padding-top: 10vh;
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Container = styled.div`
  background-color: rgba(231, 231, 231, 1);
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;

const Frame = styled.div`
  flex: 1 1 0%;
  margin-bottom: 46px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Name1 = styled.div`
  height: 59px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const NameInput1 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 13px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: black;
`;

const Group2 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const Phone1 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const Email1 = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const EmailInput1 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(255, 255, 255, 1);
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 13px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: black;
`;

const NomeEmpresa = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const PasswordInput1 = styled.input`
  font-family: Arial;
  height: 30px;
  color: rgba(255, 255, 255, 1);
  flex: 1 1 0%;
  margin-right: 17px;
  margin-left: 13px;
  margin-top: 14px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: black;
`;

const CNpj = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const CEP = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const Logradouro = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const Bairro = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const City = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const UF = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const HouseNumber = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const AddressComplement = styled.div`
  height: 59px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  border: 1px solid;
`;

const Group1Filler = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
`;

const Button = styled.button`
  height: 54px;
  background-color: rgba(74, 74, 74, 1) !important;
  color: white !important;
  font-weight: 500;
  font-size: 20px;
`;

export default CustomerInfo;
