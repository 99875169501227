
const Constants = {
    chavePublica: "ek_live_vRvpf4u2YmGIfB8oPnB3yHeAEjyRzI",
    viaCepBaseUrl: "https://viacep.com.br/ws/",
    customerApiBaseUrl: "https://api.zentek.com.br/v1/zentek/",
    apiZentekBaseUrl: "https://api.zentek.com.br/v1/zentek/",
    customerLoginUrl: "https://sales.zentek.com.br/CartCustomerLead",
    gatewayBaseUrl: "https://api.pagar.me/1/",

}


export default Constants;