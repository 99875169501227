import Bugsnag from "@bugsnag/js";
import axios from "axios";
import Constants from "../utils/constants";
import Endpoints from "../utils/endpoints";
import { _signOutCustomer } from "./firebaseSevice";

const customerLocalStorageKey = "YVhNZ2RYTmxjaUJoZFhSb1pXNTBhV05oZEdWaw==";

const isCustomerLogged = () => {
    return JSON.parse(localStorage.getItem(customerLocalStorageKey));
};
  
const setCustomerAsLogged = () => {
    localStorage.setItem(customerLocalStorageKey, true);
};
  
const setCustomerAsSignOut = async () => {
    localStorage.removeItem(customerLocalStorageKey);
    localStorage.removeItem("customerPlanData");
    await _signOutCustomer();
};

const createCustomer = async (payload) => {
    const url =
    Constants.customerApiBaseUrl + Endpoints.customerSignup;
    try {
        const response = await axios.post(url, payload);
        if (!response.data.result.successCode)
        throw Error(response.data.result.msg);
        return response;
    } catch (error) {
        Bugsnag.notify(error);
    }
};
  
const signInCustomer = async (payload) => {
    const url = Constants.customerApiBaseUrl + Endpoints.customerLogin;
    try {
        const responseSignIn = await axios.post(url, payload);
        if (!responseSignIn.data.result.successCode)
        throw Error(responseSignIn.data.result.msg);
        return responseSignIn;
    } catch (error) {
        Bugsnag.notify(error);
    }
};

const handleSignOutCustomer = async (windowPath) => {
    if (windowPath.includes("Customer")) {
        setCustomerAsSignOut();
    }
};

const createSubscription = async () => {
    const customerPlanData = JSON.parse(localStorage.getItem("customerPlanData"));
    const payloadSubscription = {
        payment_method: customerPlanData.paymentMethod,
        additionalEquipments: customerPlanData.plan.numberDevicesAdded,
        bill: {
            subscription_id: customerPlanData.plan._id,
            couponCode: "",
            referralCode: "",
            billSubscription: {
                regularInstallmentPrice: "",
                couponDiscount: "",
                referralDiscount: "",
                installment: customerPlanData.plan.installment,
                planLength: customerPlanData.plan.months.toString(),
                planTotalValue: customerPlanData.plan.planTotalValue,
            },
        },
    };

    try {
        
        const responseSubscription = await axios.post(
        Constants.customerApiBaseUrl+ "subscription/SubscribePlan",
        payloadSubscription,
        {
            headers: {
            Authorization: "Bearer " + customerPlanData.customer_token,
            },
        }
        );
        if (!responseSubscription.data.result.successCode)
        throw Error(responseSubscription.data.result.msg);
    } catch (error) {
        Bugsnag.notify(error);
    }
    // "https://api.zentek.com.br/v1/zentek/subscription/SubscribePlan"
};
  
const generateGatewayHash = async () => {
    const gatewayHashUrl = Constants.gatewayBaseUrl + Endpoints.gatewayHash;
    try {
        const responseHash = await axios.get(gatewayHashUrl, {
        params: {
            encryption_key: Constants.chavePublica,
        },
        });
        return responseHash;
    } catch (error) {
        Bugsnag.notify(error);
    }
};
  
const updateCard = async (token, params) => {
    // "https://api.zentek.com.br/v1/zentek/updateCardDetails"
    try {
        const responseUpdateCard = await axios.post(
        Constants.customerApiBaseUrl + "updateCardDetails",
        params,
        {
            headers: {
            Authorization: "Bearer " + token,
            },
        }
        );
        if (!responseUpdateCard.data.result.successCode)
        throw Error(responseUpdateCard.data.result.msg);
    } catch (error) {
        Bugsnag.notify(error);
    }
};
  

export {
    createCustomer,
    signInCustomer,
    createSubscription,
    generateGatewayHash,
    updateCard,
    handleSignOutCustomer,
    setCustomerAsSignOut,
    setCustomerAsLogged,
    isCustomerLogged
};
