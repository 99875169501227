// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyByfp5JBw3-JXWnv1odVT29nMWLAUJNISQ",
    authDomain: "sales-app-764f8.firebaseapp.com",
    projectId: "sales-app-764f8",
    storageBucket: "sales-app-764f8.appspot.com",
    messagingSenderId: "594117255691",
    appId: "1:594117255691:web:c5701ece7edab58b0b5d0e",
    measurementId: "G-ESM6HH3FQT"
};
firebase.initializeApp(firebaseConfig);
export default firebase;