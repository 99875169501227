import React, { useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { setBodyBackGroundColor } from "../services/commonService";
import { createSubscription } from "../services/customerService";
import { _updateCollectionDataById } from "../services/firebaseSevice";
import { notifyError } from "../services/noticationService";

function CartCustomerProfile() {
  const customerPlanData = JSON.parse(localStorage.getItem("customerPlanData"));
  const [checkDataValid, setCheckDataValid] = useState(false);
  const [checkAcceptTerms, setCheckAcceptTerms] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();

  setBodyBackGroundColor("rgba(238, 243, 255, 1)");

  const paymentMethod = {
    creditCard: "Cartão de crédito",
    boleto: "Boleto",
  };

  const onSubmit = async () => {
    if (!isValidToContinue()) return;

    const collectionName = "customerPlanData";
    const payloadAccepted = {
      acceptedAt: new Date(),
    };

    await _updateCollectionDataById(
      customerPlanData._id,
      collectionName,
      payloadAccepted
    );

    if (customerPlanData.paymentMethod === "boleto") {
      try {
        await createSubscription();

        history.push("/CartCongrats");

        const payloadFinished = {
          isFinished: true,
        };

        await _updateCollectionDataById(
          customerPlanData._id,
          collectionName,
          payloadFinished
        );
      } catch (error) {
        notifyError("Ocorreu um erro ao salvar os dados");
      }
    }

    if (customerPlanData.paymentMethod === "creditCard") {
      history.push("/CartPaymentInformation");
    }
  };

  const isValidToContinue = () => {
    let isValid = true;
    if (!checkDataValid) {
      isValid = false;
      notifyError("É necessário marcar que os dados são válidos");
    }
    if (!checkAcceptTerms) {
      isValid = false;
      notifyError("É necessário aceitar os termos");
    }
    return isValid;
  };

  const getCpfFormatted = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const getCnpfFormatted = (cnpj) => {
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  };
  const getPhoneFormatted = (phone) => {
    return phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  };

  const getValueFormatted = (value) => {
    if (value)
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    else return "R$ 00,00";
  };

  return (
    <Root>
      <Body>
        <div>
          <div>
            <h2 className="" style={{ fontSize: "25px", marginLeft: "50px" }}>
              Confirme os dados da sua assinatura
            </h2>
          </div>
        </div>
        <div className="col-12" style={{ padding: "0px 10%" }}>
          <Card className="mb-4  col-sm-6" style={{ padding: "0" }}>
            <Card.Body>
              <Card.Title
                className="font-weight-bold"
                style={{ fontSize: "25px" }}
              >
                Resumo do Plano :
              </Card.Title>
              <Card.Title
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginTop: "0.75rem",
                }}
              >
                • Plano
              </Card.Title>
              <CardText>
                <PlanoPremium>{customerPlanData.productName}</PlanoPremium>
                <R780000>
                  {getValueFormatted(
                    parseFloat(customerPlanData.plan.planValue)
                  )}
                </R780000>
              </CardText>
              {customerPlanData.plan.numberDevicesAdded > 0 && (
                <div>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      marginBottom: "0px",
                      marginTop: "0.75rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    • Equipamentos adicionais
                  </Card.Title>
                  <CardText>
                    <LoremIpsum3>
                      + {customerPlanData.plan.numberDevicesAdded}
                    </LoremIpsum3>
                    <R100000>
                      {getValueFormatted(customerPlanData.plan.devicesTotalValue)}
                    </R100000>
                  </CardText>
                </div>
              )}

              <Por3Meses className="mt-1">
                <span> Por {customerPlanData.plan.months} meses</span>
              </Por3Meses>
              <Rect></Rect>
              <Card.Title
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginTop: "0.75rem",
                  whiteSpace: "nowrap",
                }}
              >
                • TOTAL
              </Card.Title>
              <CardText>
                <R880000>
                  {getValueFormatted(parseFloat(customerPlanData.plan.planTotalValue))}
                </R880000>
              </CardText>
              <Card.Title
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginTop: "0.75rem",
                  whiteSpace: "nowrap",
                }}
              >
                • Parcelas
              </Card.Title>
              <CardText>
                <LoremIpsum2>
                  {customerPlanData.plan.months} parcelas de{" "}
                  {getValueFormatted(
                    customerPlanData.plan.planTotalValue /
                      customerPlanData.plan.months
                  )}
                </LoremIpsum2>
              </CardText>
              <Card.Title
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginTop: "0.75rem",
                  whiteSpace: "nowrap",
                }}
              >
                • Forma de Pagamento
              </Card.Title>
              <CardText>
                <p className="">
                  {" "}
                  {paymentMethod[customerPlanData.paymentMethod]}
                </p>
              </CardText>
            </Card.Body>
          </Card>
          <Card className="col-sm-6" style={{ padding: "0" }}>
            <Card.Body>
              <Card.Title
                className="font-weight-bold"
                style={{ fontSize: "25px" }}
              >
                Dados do Contato :
              </Card.Title>
              <Group3>
                <Group2 className="mb-5">
                  <SpanFormContact>
                    <b>Nome</b>
                  </SpanFormContact>
                  <OutputFormContact>{customerPlanData.name}</OutputFormContact>
                  <SpanFormContact>
                    <b>CPF</b>
                  </SpanFormContact>
                  <OutputFormContact>
                    {getCpfFormatted(customerPlanData.cpf)}
                  </OutputFormContact>
                  <SpanFormContact>
                    <b>Empresa</b>
                  </SpanFormContact>
                  <OutputFormContact>
                    {customerPlanData.companyName}
                  </OutputFormContact>
                  <SpanFormContact>
                    <b>CNPJ</b>
                  </SpanFormContact>
                  <OutputFormContact>
                    {getCnpfFormatted(customerPlanData.companyCnpj)}
                  </OutputFormContact>
                  <SpanFormContact>
                    <b>Telefone</b>
                  </SpanFormContact>
                  <OutputFormContact>
                    {getPhoneFormatted(customerPlanData.phone)}
                  </OutputFormContact>
                  <SpanFormContact>
                    <b>Endereço Comercial</b>
                  </SpanFormContact>
                  {/* <InputBgNone disabled type="text" value={getAddressFormatted(customerPlanData.address)} /> */}
                  <OutputFormContact>
                    {customerPlanData.billingAddress.secondaryAddress}
                  </OutputFormContact>
                  <SpanFormContact>
                    <b>Email</b>
                  </SpanFormContact>
                  <OutputFormContact>
                    {customerPlanData.email}
                  </OutputFormContact>
                </Group2>
                <Rect2Row>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkDataValid"
                    onChange={(event) =>
                      setCheckDataValid(event.target.checked)
                    }
                  />
                  <p
                    style={{ cursor: "pointer" }}
                    className="form-check-label"
                    // id="checkDataValid"
                  >
                    Confirmo que os dados são válidos
                  </p>
                </Rect2Row>
                <Rect3Row>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    onChange={(event) =>
                      setCheckAcceptTerms(event.target.checked)
                    }
                    id="checkTermsAndConditions"
                  />
                  <label className="form-check-label">
                    Aceito os
                    <Button
                     variant="link"
                      className="linkedin"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(true)}
                    >
                      termos e condições
                    </Button>
                    de uso da {"\n"}assinatura da Zentek
                  </label>
                </Rect3Row>
              </Group3>
            </Card.Body>
            <Card.Footer>
              <button
                style={{
                  backgroundColor: "#1193ff",
                  color: "white",
                  height: "50px",
                }}
                className="btn mb-3 col-md-11 col-11 ml-3"
                onClick={() => onSubmit()}
              >
                {customerPlanData.paymentMethod === "creditCard"
                  ? "Próximo"
                  : "Aceitar"}
              </button>
            </Card.Footer>
          </Card>
        </div>
      </Body>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-xl"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Termos de Uso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 col-sm-12">
            <p dir="ltr">
              POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE UTILIZAR OS
              SERVIÇOS.
            </p>
            <p dir="ltr">1.</p>
            <p dir="ltr">DISPOSIÇÕES INICIAIS</p>
            <p dir="ltr">1.1.</p>
            <p dir="ltr">
              O presente instrumento de Termos e Condições de Uso (“Termos”)
              estabelece as condições para o acesso e o uso, por qualquer
              terceiro (“Usuário” ou “Usuários”), da Plataforma Zentek, adiante
              definida, de titularidade da ZENTEK SERVICOS EM TECNOLOGIA EIRELI,
              pessoa jurídica de direito privado, estabelecida no Brasil, com
              sede na cidade de Barueri, estado de São Paulo, na Rua Werner
              Goldberg, 179 , CEP 06414-025, inscrita no CNPJ sob o nº
              05.379.848/0001-97 (“Zentek”).
            </p>
            <p dir="ltr">1.2.</p>
            <p dir="ltr">
              Ao utilizar a Plataforma Zentek e/ou os Serviços, o Usuário
              declara ter lido, entendido e aceito a integralidade destes
              Termos, vinculando-se e obrigando-se a cumprir os Termos em sua
              integralidade. Fica vedada a utilização dos Serviços e da
              Plataforma Zentek por qualquer pessoa juridicamente incapaz e/ou
              que não concorde com a integralidade dos Termos.
            </p>
            <p dir="ltr">1.3.</p>
            <p dir="ltr">
              Estes Termos podem ser alterados a qualquer momento, a critério da
              Zentek, sempre informando previamente o Usuário por meio de nota
              em destaque que será postada nos Apps e/ou no website &nbsp;
              <a href="https://zentek.com.br/termo-de-uso/">
                https://zentek.com.br/termo-de-uso/
              </a>
              &nbsp; ou por email.
            </p>
            <p dir="ltr">
              Caso o Usuário não concorde com as alterações, deverá cessar toda
              e qualquer utilização dos Serviços, nos termos do item 1.2, acima.
            </p>
            <p dir="ltr">1.4.</p>
            <p dir="ltr">
              A Zentek possui política expressa sobre o tratamento de
              informações coletadas dos Usuários, a qual está disciplinada na
              política de privacidade disponibilizada no website &nbsp;
              <a href="https://zentek.com.br/politica-de-privacidade/">
                https://zentek.com.br/politica-de-privacidade/
              </a>
              &nbsp; e constitui parte integrante dos Termos (“Política de
              Privacidade”). O Usuário declara ter lido, entendido e aceito o
              tratamento dos dados, inclusive pessoais, previsto na Política de
              Privacidade.
            </p>
            <p dir="ltr">1.5.</p>
            <p dir="ltr">
              A Plataforma Zentek pode conter links a conteúdo, incluindo
              websites e aplicativos, de titularidade de terceiros. Estes Termos
              não abrangem tais conteúdos, não podendo a Zentek ser
              responsabilizada pelos termos e condições de uso, políticas de
              privacidade e/ou conteúdos de terceiros, no limite da lei vigente
              e aplicável.
            </p>
            <p dir="ltr">1.6.</p>
            <p dir="ltr">
              Para os efeitos dos Termos, exceto se expressamente indicado de
              outra forma ou se o contexto claramente indicar o contrário, todos
              os termos iniciados com letra maiúscula, no singular ou no
            </p>
            <p dir="ltr">
              plural, conforme o caso, terão o significado a eles atribuído nos
              presentes Termos.
            </p>
            <p dir="ltr">1.6.1.</p>
            <p dir="ltr">
              Os seguintes termos terão o significado que lhes é atribuído
              abaixo:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">Apps:</p>
            <p dir="ltr">
              Aplicativos desenvolvidos pela Zentek para dispositivos móveis com
              base nas plataformas da Apple Inc., Google, Inc. e Microsoft
              Corporation, quais sejam, respectivamente: Apple iOS e Android,
              disponíveis na Apple App Store e Google Play, respectivamente,
              conforme aplicável. Os Apps são disponibilizamos em diferentes
              versões, para Técnicos e Clientes, que podem ou não ser
              disponibilizados nas plataformas acima listadas;
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">Clientes:</p>
            <p dir="ltr">
              Pessoas, naturais ou jurídicas, previamente cadastradas na
              Plataforma Zentek, que se utilizam desta plataforma para
              contratação dos Serviços;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              Conta: Conta pessoal de cadastro do Usuário de Serviços na
              Plataforma Zentek;
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              Plataforma Zentek: os Apps e o website Zentek, disponível no{" "}
              <a href="https://zentek.com.br/">https://zentek.com.br/</a>,
              quando referidos em conjunto. A Plataforma Zentek automatiza a
              busca de Técnicos por Clientes por meio da geolocalização e skill;
            </p>
            <p dir="ltr">(v)</p>
            <p dir="ltr">Serviços: conforme definição no item 2.1, abaixo;</p>
            <p dir="ltr">(vi)</p>
            <p dir="ltr">
              Técnicos: Pessoas, naturais ou jurídicas, previamente cadastradas
              na Plataforma Zentek e devidamente avaliadas pela Zentek, que são
              profissionais na área de tecnologia da informação para prestação
              dos Serviços; e
            </p>
            <p dir="ltr">(vii)</p>
            <p dir="ltr">Usuário: Clientes e Técnicos referidos em conjunto.</p>
            <p dir="ltr">2.</p>
            <p dir="ltr">SERVIÇOS</p>
            <p dir="ltr">2.1.1.</p>
            <p dir="ltr">
              A conexão entre Técnicos e Clientes se dará de forma automatizada
              pela Plataforma Zentek, de acordo com os critérios de
              geolocalização e skill nela contidos.
            </p>
            <p dir="ltr">2.2.</p>
            <p dir="ltr">
              Após a solicitação pelo Cliente e o aceite do Técnico, por meio da
              Plataforma Zentek, o Técnico deverá, sem caráter de exclusividade
              prestar, para o Cliente, serviços de tecnologia da informação, os
              quais podem incluir (“Atendimento”):
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">suporte básico para redes;</p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              suporte técnico presencial e manutenção/atualização dos softwares
              instalados nas estações de trabalho (desktops, notebooks, tablets,
              dispositivos móveis de telefonia, celulares, smartphones,
              impressoras, videoconferência, projetores e periféricos) do
              Cliente;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              gestão das atualizações de segurança e de monitoramento do
              ambiente de tecnologia da informação do Cliente;
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              diagnóstico e solução de falhas em sistemas de informática;
            </p>
            <p dir="ltr">(v)</p>
            <p dir="ltr">
              resolução de problemas de configuração e instalação, mediante
              investigação e diagnóstico dos sintomas e a resolução dos
              problemas apresentados pelos equipamentos;
            </p>
            <p dir="ltr">(vi)</p>
            <p dir="ltr">
              suporte de campo, que deve ser entendido como o deslocamento e
              prestação dos serviços, pelo Técnico nas instalações (in loco) do
              Cliente; e
            </p>
            <p dir="ltr">(vii)</p>
            <p dir="ltr">serviços de IMACD (requisições de serviços).</p>
            <p dir="ltr">2.2.1.</p>
            <p dir="ltr">
              A prestação dos Serviços depende da disponibilidade de Técnicos no
              momento e local da solicitação do Atendimento, estando o Cliente
              ciente que poderá haver situações em que não haja disponibilidade
              de Técnicos, especialmente após horário comercial.
            </p>
            <p dir="ltr">2.3.</p>
            <p dir="ltr">
              Sem prejuízo do disposto no item 2.2, acima a Zentek
              disponibilizará, na Plataforma Zentek, na versão do Web exclusiva
              para Clientes, todas as opções de Atendimento a serem contratados
              pelos Clientes cadastrados.
            </p>
            <p dir="ltr">2.3.1.</p>
            <p dir="ltr">
              O Cliente que desejar contratar um Técnico para realizar um ou
              mais Atendimentos deverá preencher os campos relativos às
              especificações dos Atendimentos, tais como discriminação dos
              serviços, local da prestação dos serviços, horário do Atendimento
              e condições de pagamento. O Cliente declara-se ciente de que a
              qualidade técnica, pontualidade e correção do Atendimento
              realizado pelo Técnico dependerá, inclusive, da adequação das
              especificações inseridas, pelo
            </p>
            <p dir="ltr">Cliente, na Plataforma Zentek.</p>
            <p dir="ltr">2.3.1.</p>
            <p dir="ltr">
              Por sua vez, a Zentek disponibilizará, na versão do App e WebApp
              exclusivas para Técnicos cadastrados, todas as informações
              necessárias à prestação do Atendimento (especificações dos
              serviços, localidade e condições de pagamento), definidas pelo
              Cliente na forma do item 2.3.1, acima.
            </p>
            <p dir="ltr">2.4.</p>
            <p dir="ltr">
              Procedimento. O Cliente, devidamente cadastrado, de acordo com a
              Seção 3, abaixo, poderá solicitar a prestação de Atendimento na
              Plataforma Zentek, que ocorrerá da seguinte forma:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              O Cliente deverá abrir chamado na Plataforma Zentek, mediante
              acesso de sua Conta (“Chamado”). O Chamado será exibido para os
              Técnicos que estiverem disponíveis para atendimento remoto se
              aplicável ou estiverem geograficamente próximos ao local de
            </p>
            <p dir="ltr">
              Atendimento, de acordo com os critérios de geolocalização da
              Plataforma Zentek Aberto o Chamado, os Técnicos cadastrados na
              plataforma, remotos ou de campo, poderá a critério deste aceitar
              ou não o serviço;
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              O primeiro Técnico que aceitar o Atendimento terá o compromisso de
              realizar o atendimento, de acordo com o especificado pelo Cliente
              na abertura da solicitação por meio da Plataforma Zentek;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              Tendo sido aceito o Chamado pelo Técnico, o Cliente receberá
              confirmação de aceitação através da Plataforma Zentek, e o Técnico
              entrará em contato para atendimento quando este for remoto ou se
              deslocará para o local indicado pelo Cliente no Chamado para
              prestação dos Serviços, no horário definido pelo Cliente
              (“Deslocamento”);
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              Caberá ao Técnico contactar o cliente quando estiver próximo do
              atendimento, quando este for remoto. Caso o atendimento seja in
              loco, o técnico informará via App que está presente no local para
              efetuar o atendimento presencial. Neste momento o técnico dará
              início ao atendimento, a plataforma Zentek notificará ao Cliente
              via email, sms ou Whatsapp
            </p>
            <p dir="ltr">(v)</p>
            <p dir="ltr">
              Uma vez finalizado o Atendimento notificará o encerramento e
              status ao cliente e fará coleta da assinatura em caso de
              atendimento onsite ou notificará via mensagem em caso de
              atendimento remoto
            </p>
            <p dir="ltr">2.4.1.</p>
            <p dir="ltr">
              Com o objetivo de assegurar, para Clientes e Técnicos, maior
              segurança quando da realização dos Atendimentos, é proibida a
              realização do Atendimento por terceiros que não sejam o Técnico
              que aceitou o Chamado por meio da Plataforma Zentek, incluindo
              eventuais subcontratados.
            </p>
            <p dir="ltr">2.4.2.</p>
            <p dir="ltr">
              O Usuário declara e concorda que os Técnicos ou a Zentek não estão
              obrigados a apresentar solução definitiva para a solicitação que
              originou o Chamado do Cliente, dado que neste momento o Cliente
              reconhece que a solução de tal solicitação poderá depender de
              fatores adversos, tais como insumos, materiais, equipamentos,
              disponibilidade de serviços externos e cloud e outros fatores
              relacionados ao ambiente de tecnologia da informação do Cliente,
              mas sem que isso se limite aos itens listados.
            </p>
            <p dir="ltr">2.4.3.</p>
            <p dir="ltr">
              Durante o atendimento, o contato entre Clientes e Técnicos poderá
              ser realizada via Chat da Plataforma Zentek, sem geração de ônus.
            </p>
            <p dir="ltr">2.5.</p>
            <p dir="ltr">
              O Cliente entende e declara que o escopo dos Atendimentos,
              prestados diretamente pelos Técnicos e exclusivamente aos
              Clientes, não devem ser utilizados para qualquer finalidade que
              não as expressamente autorizadas por estes Termos.
            </p>
            <p dir="ltr">2.6.</p>
            <p dir="ltr">
              Limitação de responsabilidade. O Cliente/Usuário desde já
              reconhece, declara e atesta que o Atendimento é feito diretamente
              entre o Cliente e os Técnicos, profissionais autônomos que não
              possuem qualquer forma de vínculo empregatício, societário ou de
              subordinação com a Zentek, nem de qualquer de suas afiliadas, seus
              sócios ou administradores. O escopo dos Serviços prestados pela
              Zentek se restringe ao licenciamento da Plataforma Zentek, de tal
              forma que a Zentek não se responsabiliza por fatos adversos
              ocorridos durante o Atendimento, nem por quaisquer perdas,
              prejuízos ou danos decorrentes ou relativos ao Atendimento,
              conforme previsto no item 9, abaixo.
            </p>
            <p dir="ltr">2.7.</p>
            <p dir="ltr">
              Modificação ou encerramento dos Serviços. A Zentek se reserva o
              direito de, a qualquer tempo, modificar ou descontinuar,
              temporariamente ou permanentemente, os Serviços ou parte dele, com
              ou sem notificação, ou, ainda, encerrar o funcionamento da
              Plataforma Zentek. O Cliente/Usuário concorda que a Zentek não
              será responsabilizada, nem terá qualquer obrigação adicional,
              implícita ou explícita, para com o Usuário ou terceiros em razão
              de qualquer modificação, suspensão ou desativação dos Serviços ou
              da Plataforma Zentek.
            </p>
            <p dir="ltr">3.</p>
            <p dir="ltr">CADASTRO</p>
            <p dir="ltr">3.1.</p>
            <p dir="ltr">
              Para utilizar os Serviços e solicitar Atendimentos, o Usuário deve
              cadastrar-se na Plataforma Zentek e manter conta pessoal,
              exclusiva e intransferível (“Conta”), com login e senha de acesso
              (“Senhas de Acesso”).
            </p>
            <p dir="ltr">3.1.1.</p>
            <p dir="ltr">
              Para cadastrar-se na Plataforma Zentek, o Usuário declara ter
              capacidade civil e idade mínima de 18 (dezoito) anos de idade.
            </p>
            <p dir="ltr">3.1.2.</p>
            <p dir="ltr">
              O Cliente/Usuário não poderá ter mais de uma conta cadastrada na
              Plataforma.
            </p>
            <p dir="ltr">3.1.3.</p>
            <p dir="ltr">
              O Usuário entende e concorda que o seu Cadastro está sujeito à
              análise pela Zentek, de acordo com critérios de necessidade,
              segurança e adequação aos presentes Termos, definidos pela Zentek,
              razão pela qual o Usuário reconhece, desde já, que a criação da
              Conta não gera qualquer direito ou expectativa de direito para
              ele, Usuário, quanto à realização dos Serviços ou o acesso à
              Plataforma Zentek.
            </p>
            <p dir="ltr">3.2.</p>
            <p dir="ltr">
              São obrigatórios para o cadastro do Usuário os seguintes dados
              pessoais, sem prejuízo de outros que venham a ser solicitados pela
              Plataforma Zentek: nome, endereço, e-mail, número de telefone para
              contato, número de RG e CPF ou CNPJ e, pelo menos, um método de
              pagamento/recebimento de valores válido, tais como cartão de
              crédito, conta bancária e/ou parceiro de pagamento aceito pela
              Zentek. Mais informações sobre o tratamento de tais dados estão
              contidos na Política de Privacidade.
            </p>
            <p dir="ltr">3.2.1.</p>
            <p dir="ltr">
              Com o objetivo de assegurar, para Clientes e Técnicos, maior
              segurança quando da realização dos Atendimentos, o Técnico que
              desejar utilizar a Plataforma Zentek precisa, obrigatoriamente,
              disponibilizar as seguintes informações à Zentek, sem prejuízo de
              outras que possam ser solicitadas posteriormente pela Plataforma
              Zentek: comprovante de residência; realização de prova de
              desempenho técnico; e comprovante de microempreendedor individual
              (MEI) ou de pessoa jurídica, mediante apresentação do cartão de
              CNPJ. O Técnico entende que certas informações pessoais
              disponibilizadas à Zentek podem ser divulgadas aos Clientes para
              segurança do Atendimento, tudo em conformidade com a Política de
              Privacidade.
            </p>
            <p dir="ltr">3.4.</p>
            <p dir="ltr">
              O Usuário concorda em manter informações corretas, completas e
              atualizadas em sua Conta. Em caso de não confirmação e/ou
              desatualização de seus dados, a Zentek poderá, a seu exclusivo
              critério, suspender temporária ou permanentemente a Conta do
              Usuário ou, ainda, resolver estes Termos, sem qualquer aviso
              prévio e/ou aplicação de penalidade à Zentek.
            </p>
            <p dir="ltr">3.5.</p>
            <p dir="ltr">
              Todas as informações do cadastro são de exclusiva responsabilidade
              do Usuário, o qual concorda e se obriga a manter sempre a
              segurança e o sigilo de seus dados, incluindo, suas Senhas de
              Acesso.
            </p>
            <p dir="ltr">3.6.</p>
            <p dir="ltr">
              Os Serviços serão prestados, primariamente, dentro do Brasil. Os
              Serviços só serão prestados no exterior mediante contratação
              específica entre o Cliente e a Zentek, nos termos do item 4.3,
              abaixo, e desde que haja Técnicos disponíveis e que aceitem os
              Chamados abertos no exterior.
            </p>
            <p dir="ltr">4.</p>
            <p dir="ltr">PAGAMENTO</p>
            <p dir="ltr">4.1.</p>
            <p dir="ltr">
              O Cliente/Usuário entende que o acesso à Plataforma Zentek, bem
              como a manutenção de Conta, são gratuitos.
            </p>
            <p dir="ltr">4.1.1.</p>
            <p dir="ltr">
              A Zentek se reserva o direito de passar a cobrar pelo uso da
              Plataforma, a qualquer momento, ressalvando a devida comunicação
              por email cadastrado na plataforma.
            </p>
            <p dir="ltr">4.2.</p>
            <p dir="ltr">
              Todos os preços, taxas e outros valores para a Plataforma são
              estabelecidos no Site (as “Taxas”). Todos os valores serão
              expressos e pagos em Reais. Todas as taxas estão sujeitas a
              alterações a qualquer momento sem aviso prévio; nenhuma taxa é
              confirmada até que o processo de checkout seja concluído. As taxas
              podem incluir uma taxa de plataforma, caso em que essa taxa de
              plataforma será indicada como um item de linha na finalização da
              compra. Exceto se expressamente estabelecido neste documento ou
              conforme expressamente aprovado pela Zentek por escrito a seu
              exclusivo critério, todos os pagamentos feitos são finais e não
              reembolsáveis ​​e um usuário não terá o direito de cancelar sua
              compra por qualquer motivo. VOCÊ REPRESENTA E GARANTE QUE TEM O
              DIREITO LEGAL DE USAR QUALQUER MEIO DE PAGAMENTO USADO PARA
              INICIAR QUALQUER TRANSAÇÃO. Todas as informações que você fornece
              a nós ou a nossos processadores de pagamentos terceirizados devem
              ser precisas, atuais e completas. Você também será responsável
              pelo pagamento de quaisquer impostos aplicáveis ​​relacionados aos
              pagamentos que você fizer. A Zentek não terá nenhuma
              responsabilidade ou responsabilidade para com você ou qualquer
              outro terceiro em conexão com a precisão das informações do
              Provedor de pagamento fornecidas por você ou seu uso de qualquer
              Provedor de pagamento (incluindo quaisquer taxas cobradas por tal
              Provedor de pagamento em conexão com tal uso).
            </p>
            <p dir="ltr">4.2</p>
            <p dir="ltr">
              Processo de pagamento. Quando você fizer uma solicitação, a
              plataforma fará uma confirmação e consequente cobrança do serviço
              solicitado e revisado durante o checkout.. Você pode alterar o
              método de pagamento no momento da solicitação do serviço. As taxas
              para cancelamentos e reservas reprogramadas, de acordo com as
              políticas de cancelamento e reprogramação referidas na Seção 5,
              serão cobradas no cartão de crédito fornecido no momento da
              reserva.
            </p>
            <p dir="ltr">4.3.</p>
            <p dir="ltr">
              Transações com falha. Caso a Zentek não consiga cobrar com sucesso
              qualquer instrumento de pagamento vinculado, a Zentek irá
              notificá-lo por e-mail e / ou na página “Minha conta”, e você não
              poderá iniciar outros pedidos ou transações com sua conta. Após a
              notificação da Zentek a você de tal falha, você concorda em
              vincular um novo instrumento de pagamento válido o mais rápido
              possível, mas em qualquer caso, dentro de três (3) dias úteis. Sua
              conta Zentek pode ser desativada até que um instrumento de
              pagamento válido seja fornecido e o saldo pendente não cobrado
              seja resolvido pela Zentek. A Zentek não é responsável por
              quaisquer cobranças impostas pelo emissor do seu instrumento de
              pagamento como resultado de qualquer cobrança falhada pela Zentek.
              Na medida em que a Zentek é incapaz de cobrar com sucesso qualquer
              instrumento de pagamento vinculado à sua conta como pagamento de
              transações autorizadas por mais de trinta (30) dias, você entende
              e concorda que a Zentek pode empregar uma agência de cobrança ou
              outro negócio em um esforço para cobrar quaisquer dívidas
              pendentes e taxas associadas à sua conta de usuário, e você não
              contestará o uso de terceiros para cobrar a dívida e taxas devidas
              à Zentek. Você concorda que será responsável por quaisquer taxas
              legais, judiciais, de arbitragem ou de agência de cobrança
              associadas à retificação de sua conta e todo o dinheiro devido.
              Você concorda que a Zentek, ou qualquer agência ou empresa
              empregada pela Zentek, tem o direito de contatar você e seus
              herdeiros por telefone, e-mail ou pessoalmente usando as
              informações que você forneceu durante o registro ou durante
              qualquer contato com a Zentek, em um esforço para coletar
              quaisquer quantias e taxas devidas em sua conta, sejam
              especificamente referenciadas nestes Termos ou não, e tal contato
              pode ser feito de forma manual ou automatizada.
            </p>
            <p dir="ltr">4.4</p>
            <p dir="ltr">
              (i) Se você comprar um plano de assinatura (um "Plano de
              assinatura"), será cobrada a taxa de assinatura, mais quaisquer
              impostos aplicáveis, mais taxa de configuração (se aplicável) e
              outras taxas ("Taxa de assinatura"), no início de seu Plano de
              assinatura, a cada período de assinatura (por exemplo, mensal ou
              anualmente, conforme especificado quando você compra tal Plano de
              assinatura) posteriormente. Planos de assinatura e taxas de
              assinatura são descritos em mais detalhes em{" "}
              <a href="https://www.zentek.com/planos">
                https://www.zentek.com/planos
              </a>{" "}
              , e podem ser atualizados de tempos em tempos sem aviso prévio
              (exceto que a taxa mensal para qualquer "Plano anual, pago
              mensalmente" (se essa opção é selecionada na finalização da
              compra) permanecerá bloqueada durante o primeiro ano de tal Plano
              de Assinatura).
            </p>
            <p dir="ltr">
              (ii) Os planos de assinatura tornam-se ativos assim que seu
              pagamento inicial é processado. Se você selecionar a opção “Plano
              anual, pré-pago”, toda a Taxa de assinatura anual aplicável mais
              os impostos aplicáveis ​​serão cobrados no pagamento inicial. Se
              você selecionar a opção "Plano anual, pago mensalmente", a taxa de
              assinatura aplicável do primeiro mês mais a taxa de configuração
              serão cobradas no pagamento inicial e, posteriormente, a taxa de
              assinatura mensal mais os impostos aplicáveis ​​serão cobrados a
              cada mês pela duração do ano aplicável para esse Plano de
              Assinatura anual.
            </p>
            <p dir="ltr">
              (iii) Se você se inscrever em uma assinatura de avaliação
              gratuita, a assinatura de avaliação gratuita será automaticamente
              convertida em uma assinatura paga no final do período de avaliação
              (cujo período de avaliação será indicado no momento da inscrição
              em tal período de avaliação). Salvo indicação em contrário, as
              assinaturas de avaliação gratuita serão automaticamente
              convertidas no Plano Anual de Suporte Online da Zentek, com
              cobrança mensal. Você pode cancelar a conversão automática em uma
              assinatura paga de “Minha conta” ou contatando o suporte da zentek
              em suporte@zentek.com.br a qualquer momento ou antes do último dia
              útil antes que a conversão automática esteja programada para
              entrar em vigor.
            </p>
            <p dir="ltr">
              (iv) Seu Plano de Assinatura será renovado automaticamente no
              final do período de assinatura aplicável (por exemplo, no final do
              período de um ano para um Plano de Assinatura anual) até que você
              desligue a renovação automática. As taxas de renovação estão
              sujeitas a alterações, mas você será notificado sobre qualquer
              alteração em sua taxa e terá a opção de cancelar de acordo com
              estes Termos. A renovação automática do plano pode ser desativada
              a qualquer momento (mas deve ser feita ou até o último dia útil
              antes que a renovação automática seja agendada para entrar em
              vigor, a fim de evitar ser cobrado por esse período renovado), no
              entanto, o plano permanecerá ativo pelo restante do período do
              plano anual e você será responsável pelos encargos aplicáveis
              ​​para os meses restantes do período do plano anual. Você pode
              desligar a opção de renovação automática em “Minha conta” ou
              entrando em contato com o suporte da zentek em
              suporte@zentek.com.br . Se você optar por não renovar, seu direito
              de usar as Plataformas sob o Plano de assinatura continuará até o
              final do período de assinatura atual e, em seguida, será encerrado
              sem custos adicionais.
            </p>
            <p dir="ltr">
              (v) Falhas no pagamento resultarão na desativação imediata do (s)
              Plano (s) de Assinatura aplicável (eis). A Zentek não estará
              disponível para usuários com plano desativado por falha de
              pagamento. Quaisquer pagamentos pendente
            </p>
            <p dir="ltr">5.</p>
            <p dir="ltr">CONDUTA E OBRIGAÇÕES DO USUÁRIO</p>
            <p dir="ltr">5.1.</p>
            <p dir="ltr">
              O Usuário concorda em somente utilizar os Serviços para
              finalidades legítimas, em cumprimento destes Termos e de todas as
              leis aplicáveis, arcando com todas as penalidades, danos e
              consequências de assim não fazê-lo.
            </p>
            <p dir="ltr">5.2.</p>
            <p dir="ltr">
              O Usuário deverá responsabilizar-se pelo uso, guarda, manutenção e
              sigilo de todas as Senhas de Acesso, garantindo à Zentek que não
              irá compartilhar as Senhas de Acesso com quaisquer terceiros,
              exceto seus autorizados, que as utilizarão somente em benefício do
              próprio Usuário.
            </p>
            <p dir="ltr">5.3.</p>
            <p dir="ltr">
              Além de outras obrigações previstas nestes Termos, o Usuário se
              obriga ao cumprimento das seguintes disposições:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              não ceder, nem de qualquer outro modo transferir, sua Conta a
              nenhuma outra pessoa ou entidade
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              não permitir que pessoas menores de 18 (dezoito) anos recebam
              utilizem os Serviços;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              assegurar aos Técnicos o acesso ao(s) local(is) onde os Serviços
              serão executados, bem como a todas as informações necessárias ao
              atendimento do Chamado;
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              efetuar o pagamento do Serviço correspondentes aos Atendimentos
              contratados por meio da Plataforma Zentek, bem como das demais
              despesas incorridas pelos Técnicos quando da realização dos
              Atendimentos; e
            </p>
            <p dir="ltr">(v)</p>
            <p dir="ltr">
              causar, direta ou indiretamente, quando da utilização dos
              Serviços, transtorno, aborrecimento, inconveniente ou danos à
              propriedade de outros Usuários, da Zentek e quaisquer de suas
              afiliadas e/ou terceiros em geral.
            </p>
            <p dir="ltr">5.4.</p>
            <p dir="ltr">
              Prezando pela segurança e transparência na prestação dos Serviços,
              o Usuário, em especial o Cliente, poderá solicitar e ser
              solicitado a fornecer comprovante de identidade para acessar a
              Plataforma Zentek e/ou utilizar os Serviços, e está ciente de que
              poderá ter seu acesso e/ou utilização dos Serviços negado, caso se
              recuse a fornecer comprovante de identidade.
            </p>
            <p dir="ltr">5.5.</p>
            <p dir="ltr">
              Os vícios e defeitos eventualmente identificados nos Atendimentos,
              após a sua conclusão, devem ser comunicados imediatamente à Zentek
              pelo Cliente, por meio da Plataforma Zentek, imediatamente após
              sua constatação, para que a Zentek realize a sua análise e, se for
              o caso, comunique o Técnico e adote as devidas providências.
            </p>
            <p dir="ltr">5.6.</p>
            <p dir="ltr">É de inteira responsabilidade do Usuário, ainda:</p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              equipar-se e responsabilizar-se pelos computadores e dispositivos
              de hardware e software necessários para o à Plataforma Zentek, bem
              como pelo acesso desses computadores e dispositivos à internet;
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              manter o ambiente de seus computadores e demais dispositivos de
              acesso à Plataforma Zentek seguros, com o uso de ferramentas
              disponíveis como antivírus e firewall, entre outras, de modo a
              contribuir na prevenção de riscos eletrônicos; e
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              utilizar sistemas operacionais e navegadores atualizados e
              eficientes para o pleno acesso da Plataforma Zentek, de acordo com
              as especificações fornecidas pela Zentek.
            </p>
            <p dir="ltr">5.6.1.</p>
            <p dir="ltr">
              O Usuário, ainda, entende, concorda e declara que a Zentek não é
              responsável pelo acesso à rede de dados e equipamentos, nem pela
              disponibilidade, qualidade e manutenção de tais serviços de
              telecomunicação. Neste sentido, a Zentek não será
              responsabilizada, em qualquer grau, em juízo ou fora dele, por
              qualquer problema relacionado aos Serviços e ao funcionamento da
              Plataforma Zentek, decorrente, direta ou indiretamente, de
              inconsistências, atrasos, maus funcionamentos e/ou falhas em
              geral, inerentes ao uso da Internet e de comunicações eletrônicas
              e/ou digitais, nos dispositivos de telefonia móvel e/ou nos
              serviços de telecomunicação.
            </p>
            <p dir="ltr">6.</p>
            <p dir="ltr">PROPRIEDADE INTELECTUAL</p>
            <p dir="ltr">6.1.</p>
            <p dir="ltr">
              O Usuário entende, aceita e concorda que a Plataforma Zentek, bem
              como todas as invenções, técnicas, metodologias, banco de dados e
              os dados contidos nos mesmos, códigos e linguagens de programação,
              avançadas ou não, desenvolvidas pela Zentek, são produtos da
              tecnologia e do conhecimento desenvolvidos pela e pertencentes à
              Zentek, sendo de propriedade exclusiva da Zentek.
            </p>
            <p dir="ltr">6.1.1.</p>
            <p dir="ltr">
              Os desenhos, patentes, logotipo, nome, marcas, know-how, software,
              segredos de negócio, processos, fórmulas e designs, patenteáveis
              ou não, planos de negócios (business plans), métodos
            </p>
            <p dir="ltr">
              de contabilidade, técnicas e experiências acumuladas, documentos,
              contratos, papéis, estudos, pareceres e pesquisas e quaisquer
              outros dados e informações de propriedade intelectual da Zentek,
              de qualquer natureza, patenteadas ou não, que sejam utilizados na
              execução dos Serviços e/ou na Plataforma Zentek, por qualquer meio
              físico, eletrônico ou gráfico, são de exclusiva propriedade da
              Zentek.
            </p>
            <p dir="ltr">6.1.2.</p>
            <p dir="ltr">
              Estes Termos e o uso dos Serviços não outorgam nem conferem aos
              Usuários qualquer direito de usar, explorar comercialmente ou, de
              qualquer modo, fazer referência a nomes empresariais, logotipos,
              marcas e/ou nomes de produtos, incluindo a Plataforma Zentek, da
              Zentek e de suas licenciadoras.
            </p>
            <p dir="ltr">6.2.</p>
            <p dir="ltr">
              Desde que integralmente cumpridas as condições destes Termos, a
              Zentek concede ao Usuário uma licença limitada, pessoal, não
              exclusiva, não passível de sublicença, intransferível e revogável
              para acesso e utilização da Plataforma Zentek no dispositivo
              pessoal do Usuário, exclusivamente para a utilização dos Serviços.
            </p>
            <p dir="ltr">6.2.1.</p>
            <p dir="ltr">
              Quaisquer direitos sobre a Plataforma Zentek não expressamente
              outorgados em favor do Usuário por estes Termos são reservados à
              Zentek, em caráter exclusivo.
            </p>
            <p dir="ltr">6.3.</p>
            <p dir="ltr">
              O Usuário não poderá, exceto da forma expressamente permitida pela
              Zentek:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              remover qualquer aviso de direito autoral, direito de marca ou
              outro aviso de direito de propriedade de qualquer parte dos
              Serviços;
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              reproduzir, preparar obras derivadas, distribuir, ceder,
              sublicenciar, locar, vender, revender, doar, transferir, exibir,
              veicular, revelar, duplicar, copiar, transmitir ou, de qualquer
              outro modo, explorar os Serviços e/ou a Plataforma Zentek;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              decompilar, modificar, ampliar, alterar, mesclar, incorporar,
              adaptar, realizar engenharia reversa ou desmontar total ou
              parcial, permanente, temporária ou provisória, de forma gratuita
              ou onerosa, sob quaisquer modalidades, formas ou títulos da
              Plataforma Zentek;
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              desenvolver, criar ou patrocinar quaisquer programas que possam
              alterar ou copiar a Plataforma Zentek, ainda que seja para
              introduzir melhores técnicas e/ou procedimentos;
            </p>
            <p dir="ltr">(v)</p>
            <p dir="ltr">
              conectar, espelhar ou recortar qualquer parte dos Serviços;
            </p>
            <p dir="ltr">(vi)</p>
            <p dir="ltr">
              fazer ou lançar quaisquer programas ou scripts com a finalidade de
              fazer scraping, indexação, pesquisa ou qualquer outra forma de
              obtenção de dados de qualquer parte dos Serviços, ou de
              sobrecarregar ou prejudicar indevidamente a operação e/ou
              funcionalidade de qualquer aspecto dos Serviços; ou
            </p>
            <p dir="ltr">(vii)</p>
            <p dir="ltr">
              tentar obter acesso não autorizado à Plataforma Zentek ou seus
              sistemas ou redes correlatas.
            </p>
            <p dir="ltr">7.</p>
            <p dir="ltr">CONTEÚDO FORNECIDO PELO USUÁRIO</p>
            <p dir="ltr">7.1.</p>
            <p dir="ltr">
              A Zentek poderá, a seu exclusivo critério, permitir que o Usuário
              apresente, carregue, publique ou, de qualquer modo, disponibilize
              para a Zentek, por meio da Plataforma Zentek, conteúdo e
              informações de texto, áudio ou vídeo, inclusive comentários e
              feedbacks relacionados aos Serviços e aos Atendimentos, inclusive,
              mas sem limitação, iniciação de solicitação de suporte e registro
              em concursos e promoções (“Conteúdo de Usuário“).
            </p>
            <p dir="ltr">7.2.</p>
            <p dir="ltr">
              Qualquer Conteúdo de Usuário fornecido pelo Usuário permanece na
              titularidade do Usuário. Contudo, ao fornecer para a Zentek, o
              Conteúdo de Usuário será regido pela Política de Privacidade da
              Zentek.
            </p>
            <p dir="ltr">7.3.</p>
            <p dir="ltr">
              O Usuário declara e garante que: (i) é o único e exclusivo
              proprietário de todo Conteúdo de Usuário ou tem todos os direitos,
              licenças, consentimentos e liberações necessários para outorgar à
              Zentek a licença sobre o Conteúdo de Usuário acima referido; e
              (ii) nem o Conteúdo de Usuário nem
            </p>
            <p dir="ltr">
              sua apresentação, carregamento, publicação ou outra forma de
              disponibilização desse Conteúdo de Usuário, tampouco o uso do
              Conteúdo de Usuário pela Zentek da forma aqui e na Política de
              Privacidade permitida infringirá, constituirá apropriação indevida
              nem violará propriedade intelectual ou direito de propriedade de
              terceiros, nem direitos de publicidade ou privacidade e também não
              resultarão na violação de qualquer lei ou regulamento aplicável.
            </p>
            <p dir="ltr">7.4.</p>
            <p dir="ltr">
              O Usuário concorda em não fornecer Conteúdo de Usuário que seja
              difamatório, calunioso, injurioso, violento, obsceno,
              pornográfico, ilegal ou de qualquer modo ofensivo, conforme
              apuração da Zentek, a seu critério exclusivo, seja ou não esse
              Conteúdo de Usuário protegido por lei.
            </p>
            <p dir="ltr">7.5.</p>
            <p dir="ltr">
              A Zentek poderá, mas não está obrigada a, analisar, monitorar ou
              remover Conteúdo de Usuário, a critério exclusivo da Zentek, que
              esteja em descumprimento com estes Termos.
            </p>
            <p dir="ltr">8.</p>
            <p dir="ltr">CONFIDENCIALIDADE</p>
            <p dir="ltr">8.1. O</p>
            <p dir="ltr">
              Usuário reconhece que, para a execução dos Serviços, poderão ser
              compartilhadas “Informações Confidenciais” que compreendem, sem
              limitação, todas as informações, know-how, documentos, programas
              de computador e documentação, relatórios, dados financeiros,
              econômicos, contábeis, societários ou quaisquer outros dados,
              registros, formulários, ferramentas, produtos, serviços,
              metodologias, pesquisa presente e futura, conhecimento técnico,
              planos de marketing, segredos comerciais e outros materiais
              tangíveis ou intangíveis, armazenados ou não, compilados ou
              reduzidos a termo, seja física, eletrônica ou graficamente, por
              escrito, ou por qualquer meio.
            </p>
            <p dir="ltr">8.2.</p>
            <p dir="ltr">O Usuário compromete-se a:</p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              usar as Informações Confidenciais a que tiver acesso apenas com o
              único propósito do bom e fiel cumprimento da prestação dos
              Serviços;
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              proteger as Informações Confidenciais a que tiver acesso, usando o
              mesmo grau de zelo e cuidado utilizado para proteger as suas
              próprias informações confidenciais;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              manter o mais completo e absoluto sigilo sobre a totalidade das
              Informações Confidenciais, não podendo divulgar, revelar,
              reproduzir, utilizar, ou delas dar conhecimento a terceiros, salvo
              mediante autorização expressa por escrito da Zentek;
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              manter procedimentos administrativos adequados à prevenção de
              extravio ou perda de quaisquer documentos ou Informações
              Confidenciais, devendo comunicar imediatamente à Zentek a
              ocorrência de incidentes desta natureza, o que não excluirá a
              responsabilidade do Usuário.
            </p>
            <p dir="ltr">8.2.1.</p>
            <p dir="ltr">
              As obrigações de sigilo e confidencialidade não se aplicam a
              informações que já sejam comprovadamente de domínio público ou que
              se tornem disponíveis para o público, sem que seja por meio de
              violação destes Termos nem a informações cuja revelação seja
              exigida por lei ou regras impostas por qualquer órgão
              governamental, devendo o Usuário informar a Zentek neste sentido,
              para que sejam adotadas as medidas cabíveis pela Zentek.
            </p>
            <p dir="ltr">8.3.</p>
            <p dir="ltr">
              Quando não for mais necessária a manutenção das Informações
              Confidenciais, o Usuário deverá:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              devolver, íntegros e integralmente, todos os documentos a ele
              fornecidos, inclusive as cópias porventura necessárias, na data
              estipulada pela Zentek para entrega, comprometendo-se a não reter
              quaisquer reproduções, cópias ou segundas vias, inclusive
              documentos gerados eletronicamente, sob pena de incorrer nas
              responsabilidades previstas nestes Termos, sem prejuízos de
              outras, assim definidas na legislação aplicável; e
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              destruir todo e qualquer documento por ele produzido que contenha
              informações confidenciais da Zentek, comprometendo-se a não reter
              quaisquer reproduções, sob pena de incorrer nas responsabilidades
              previstas nestes Termos.
            </p>
            <p dir="ltr">8.3.1.</p>
            <p dir="ltr">
              Caso o Usuário mantenha, por qualquer motivo, quaisquer documentos
              da Contratante, todas as obrigações de confidencialidade previstas
              nestes Termos continuarão plenamente válidas, vigentes e eficazes,
              por todo o prazo em que durar a retenção das Informações
              Confidenciais.
            </p>
            <p dir="ltr">8.4.</p>
            <p dir="ltr">
              Todas as obrigações de sigilo e confidencialidade serão aplicáveis
              durante toda a prestação dos Serviços, enquanto estiver ativa a
              Conta do Usuário ou, ainda, por um período mínimo de 10 (dez) anos
              da desativação da Conta, qualquer que seja o motivo.
            </p>
            <p dir="ltr">9.</p>
            <p dir="ltr">RECUSA DE GARANTIA E LIMITAÇÃO DE RESPONSABILIDADE</p>
            <p dir="ltr">9.1.</p>
            <p dir="ltr">
              O Usuário expressamente concorda e está ciente de que: (i) a
              utilização dos Atendimentos será sob inteira responsabilidade do
              Usuário. Os serviços são fornecidos ao Usuário na forma em que
              está disponível. A Zentek não oferece garantias outras além das
              expressamente estabelecidas nestes termos; e (ii) a Zentek não
              pode garantir que: (a) os Atendimentos realizados atenderão às
              necessidades do Usuário; (b) os Atendimentos serão prestados de
              forma ininterrupta, tempestiva; (c) que a qualidade de quaisquer
              outras ofertas, informações ou outro material acessado, obtido,
              disponibilizado ou prestado ao Usuário em conexão os Atendimentos
              atenderá às expectativas; e (d) que melhoramentos ou inovações
              serão implementados.
            </p>
            <p dir="ltr">9.2.</p>
            <p dir="ltr">
              O Usuário reconhece que ele e a Zentek são pessoas independentes e
              autônomas, de forma que a prestação dos Serviços não cria qualquer
              outra modalidade de vínculo entre ambas, inclusive, sem limitação,
              mandato, representação, associação, formação de grupo econômico,
              vínculo empregatício ou similar.
            </p>
            <p dir="ltr">9.3.</p>
            <p dir="ltr">
              A Zentek não será responsável, ainda que tenha sido alertada sobre
              a possibilidade neste sentido, por quaisquer defeitos, prejuízos,
              perdas, lucros cessantes, danos indiretos, incidentais, especiais,
              punitivos ou emergentes, morais ou patrimoniais, relacionados,
              associados ou decorrentes:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              do uso ou incapacidade de usar Serviços e/ou a Plataforma Zentek;
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              da qualidade e integridade do Atendimento realizado, que é
              responsabilidade exclusiva dos Técnicos e Clientes;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              de qualquer operação ou relacionamento entre o Usuário e qualquer
              prestador terceiro;
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              atos praticados pelo Usuário, sejam Clientes ou Técnicos,
              inclusive quando da realização dos Atendimentos;
            </p>
            <p dir="ltr">(v)</p>
            <p dir="ltr">
              atos praticados por ou sob a responsabilidade de terceiros;
            </p>
            <p dir="ltr">(vi)</p>
            <p dir="ltr">
              de orientações ou condutas de terceiros sobre os Serviços; e
            </p>
            <p dir="ltr">(vii)</p>
            <p dir="ltr">de força maior ou caso fortuito.</p>
            <p dir="ltr">10.</p>
            <p dir="ltr">SANÇÕES E PENALIDADES</p>
            <p dir="ltr">10.1.</p>
            <p dir="ltr">
              Prezando pela segurança e transparência na prestação dos Serviços,
              o Usuário reconhece e concorda que:
            </p>
            <p dir="ltr">(i)</p>
            <p dir="ltr">
              caso utilize a Plataforma Zentek de forma indevida, ilegal ou em
              desacordo com estes Termos, terá como consequência a exclusão da
              Conta do Usuário e/ou a proibição da utilização dos Serviços por
              até 30 (trinta) dias, a critério exclusivo da Zentek.
            </p>
            <p dir="ltr">(ii)</p>
            <p dir="ltr">
              caso o Técnico cancele 3 (três) Atendimentos consecutivos, terá
              como consequência a proibição da utilização dos Serviços por até
              30 (trinta) dias;
            </p>
            <p dir="ltr">(iii)</p>
            <p dir="ltr">
              caso o Cliente cancele Atendimento em que o Técnico já esteja em
              Deslocamento, terá como consequência a cobrança integral de 1 (um)
              UP da Conta do Cliente; e
            </p>
            <p dir="ltr">(iv)</p>
            <p dir="ltr">
              caso haja qualquer pendência financeira relativa a Atendimentos
              anteriores, não poderá solicitar novo Chamado na Plataforma
              Zentek, até a regularização do débito.
            </p>
            <p dir="ltr">10.2.</p>
            <p dir="ltr">
              O Usuário concorda em indenizar a Zentek, suas filiais, empresas
              controladas ou controladoras, diretores, administradores,
              colaboradores, representantes e empregados por qualquer perda,
              danos, responsabilização, reclamação ou demanda, por quaisquer
              prejuízos devido a, ou resultantes de, sua utilização dos
              Serviços.
            </p>
            <p dir="ltr">10.3.</p>
            <p dir="ltr">
              A não-observância de quaisquer das disposições estabelecidas
              nestes Termos sujeitará ao Usuário, como também ao agente causador
              ou facilitador, por ação ou omissão de qualquer daqueles
              relacionados nestes Termos, ao pagamento, ou recomposição, de
              todas as perdas e danos comprovadas pela Zentek, bem como as de
              responsabilidade civil e criminal respectivas, as quais serão
              apuradas em regular processo judicial ou administrativo.
            </p>
            <p dir="ltr">11.</p>
            <p dir="ltr">DISPOSIÇÕES GERAIS</p>
            <p dir="ltr">11.1.</p>
            <p dir="ltr">
              Acordo integral. Estes Termos constituem o acordo integral entre o
              Usuário e a Zentek e regerão a utilização dos Serviços pelo
              Usuário, substituindo todos os acordos, termos e acertos
              anteriores entre o Usuário e a Zentek e/ou qualquer das suas
              afiliadas.
            </p>
            <p dir="ltr">11.2.</p>
            <p dir="ltr">
              Validade. Estes Termos tornam-se válidos a partir da data de sua
              efetiva aceitação pelo Usuário. As disposições deste instrumento
              devem, contudo, ser aplicadas retroativamente a qualquer
              informação confidencial que possa já ter sido divulgada, antes da
              data de sua aceitação.
            </p>
            <p dir="ltr">11.2.</p>
            <p dir="ltr">
              Legislação Aplicável. Estes Termos e o relacionamento entre o
              Usuário e a Zentek serão regidos pelas leis da República
              Federativa do Brasil, inclusive as Leis Federais nº 8.078/1990
              (Código de Defesa do Consumidor), 9.609/1998 (Lei do Software),
              9.610/1998 (Lei de Direitos Autorais), 13.709/18 (Lei Geral de
              Proteção de Dados), com o Decreto Presidencial nº 7.962/2013
              (Regulamentação do Comércio Eletrônico), e demais normas técnicas
              e legais aplicáveis à espécie.
            </p>
            <p dir="ltr">11.3.</p>
            <p dir="ltr">
              Regras de Interpretação. Na interpretação destes Termos : (i) Os
              cabeçalhos e títulos não limitarão ou afetarão, de qualquer modo,
              a interpretação do texto, servindo apenas para conveniência e
              referência; (ii) os termos “incluindo”, “inclusive”, “inclui”,
              “incluído” e suas derivações e termos
            </p>
            <p dir="ltr">
              análogos serão interpretados como se estivessem acompanhados pela
              frase “entre outros”, e, portanto, de maneira exemplificativa e
              nunca restritiva; (iii) as referências a disposições legais e
              regulatórias devem ser interpretadas como referências a essas
              disposições conforme vigentes à época do fato a que se aplicam, e
              devem incluir quaisquer disposições das quais se originam (com ou
              sem modificações) e quaisquer decisões, regulamentos, instrumentos
              ou outras normas legais a elas subordinadas; (iv) salvo indicação
              expressa em contrário, as referências a itens referem-se itens do
              termo.
            </p>
            <p dir="ltr">11.4.</p>
            <p dir="ltr">
              Notificações. A Zentek poderá enviar avisos por meio de
              notificações gerais na Plataforma Zentek, correio eletrônico para
              o endereço de e-mail na Conta do Usuário, ou por comunicação
              escrita enviada ao endereço indicado em sua Conta. O Usuário
              poderá notificar a Zentek por meio da Plataforma Zentek.
            </p>
            <p dir="ltr">11.4.1.</p>
            <p dir="ltr">
              Todas as notificações, intimações, ofícios ou qualquer outra forma
              oficial de cientificação da Zentek deverão ocorrer em seu endereço
              de sede, descrito no item 1.1, acima.
            </p>
            <p dir="ltr">11.5.</p>
            <p dir="ltr">
              Renúncia de direitos. O não exercício pelo Usuário e/ou pela
              Zentek de quaisquer de seus direitos, ou a não execução de
              quaisquer dos termos ou condições destes Termos não serão
              considerados renúncia a esses direitos, nem impedirão de fazer
              valer ou exercer quaisquer desses direitos. A eventual tolerância,
              por qualquer das Partes, da inexecução de quaisquer cláusulas ou
              condições deste Acordo, a qualquer tempo, deverá ser interpretada
              como mera liberalidade, não implicando, portanto, em novação,
              transação, perdão, renúncia ou dispensa da obrigação assumida, nem
              desistência do cumprimento das disposições aqui contidas.
            </p>
            <p dir="ltr">11.6.</p>
            <p dir="ltr">
              Cessão de direitos. O Usuário não poderá ceder tampouco transferir
              estes Termos, total ou parcialmente, sem prévia aprovação por
              escrito da Zentek. O Usuário concede sua aprovação para que a
              Zentek ceda e transfira estes Termos total ou parcialmente,
              inclusive: (i) para sua controladora, subsidiária ou afiliada;
              (ii) um adquirente das participações acionárias, negócios ou bens
              da Zentek; ou (iii) para um sucessor em razão de qualquer operação
              societária. Não existe joint-venture, sociedade, emprego ou
              relação de representação entre o Usuário e a Zentek como resultado
              do contrato entre o Usuário e a Zentek ou pelo uso dos Serviços.
            </p>
            <p dir="ltr">11.7.</p>
            <p dir="ltr">
              Indivisibilidade. Caso qualquer disposição destes Termos seja tida
              como ilegal, inválida ou inexequível total ou parcialmente, por
              qualquer legislação, essa disposição ou parte dela será, naquela
              medida, considerada como não existente para os efeitos destes
              Termos, mas a legalidade, validade e exequibilidade das demais
              disposições contidas nestes Termos não serão afetadas.
            </p>
            <p dir="ltr">11.8.</p>
            <p dir="ltr">
              Foro. Fica eleito o foro da comarca de Barueri/SP como sendo o
              único competente para dirimir quaisquer litígios e/ou demandas que
              venham a envolver as partes em relação à Plataforma Zentek e/ou à
              prestação dos Serviços. O Usuário e a Zentek concordam em
              submeter-se à competência única e exclusiva dos tribunais
              localizados no Brasil.
            </p>
            <p dir="ltr">
              A última alteração foi publicada no dia 18 de dezembro de 2020.
            </p>
            <br />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{
              backgroundColor: "#1193ff",
              color: "white",
              height: "50px",
            }}
            className="btn mb-3 col-md-2  ml-3"
            onClick={() => setShow(false)}
          >
            OK, fechar!
          </button>
        </Modal.Footer>
      </Modal>
    </Root>
  );
}

const Body = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
`;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  // height: 100vh;
  width: 100vw;
`;

const PlanoPremium = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;

const R780000 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;
const SpanFormContact = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  font-size: 13px;
`;
const OutputFormContact = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  font-size: 14px;
  width: fit-content;
  border-radius: 6px;
  padding: 0px 5px;
`;

const LoremIpsum3 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;

const R100000 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;

const Por3Meses = styled.p`
  display: flex;
  justify-content: flex-end;
  width: 90%;
  white-space: nowrap;
  @media only screen and (min-width: 780px) {
    width: 74%;
  }
  margin: auto;
`;

const Rect = styled.div`
  height: 1px;
  background-color: #e6e6e6;
  margin-top: 8px;
  margin-left: 9px;
`;

const R880000 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;

const LoremIpsum2 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
`;

const Group3 = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 14px;
`;

const Group2 = styled.div`
  width: 280px;
  height: 205px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 22px;
  display: flex;
`;

const Rect2Row = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 80px;
  margin-left: 17px;
  margin-right: 19px;
`;

const Rect3Row = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 16px;
  margin-left: 17px;
`;
const CardText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  white-space: nowrap;
  @media only screen and (min-width: 780px) {
    width: 74%;
  }
  margin: auto;
`;

export default CartCustomerProfile;
